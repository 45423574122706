
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { RadioAudio } from '@/models/radio'
import RadioMenuAudioDialog from '@/components/RadioMenuAudioDialog.vue'

@Options({
  components: { RadioMenuAudioDialog }
})
export default class RadioMenuDetailTable extends Vue {
  @Prop()
  radioMenuId! : number

  isShowOnlineVersion = false

  dialogVisible = false

  audios: RadioAudio[] = []

  currentAudioToEdit :RadioAudio = {
    name: '',
    menuId: this.radioMenuId,
    audio: '',
    sortNumber: 10,
    lightBackground: '',
    darkBackground: '',
    demoId: undefined,
    demoVersion: undefined,
    duration: undefined,
    fileSize: undefined
  }

  handleMenuAudioUpdate (radioAudio: RadioAudio) {
    this.updateAudio(radioAudio)
  }

  private updateAudio (radioAudio: RadioAudio) {
    const index = this.audios.findIndex(p => p.id === radioAudio.id)
    if (index > -1) {
      this.audios.splice(index, 1, radioAudio)
    } else {
      this.audios.push(radioAudio)
    }
  }

  @Watch('radioMenuId')
  onRadioMenuIdChange (radioMenuId: number) {
    this.isShowOnlineVersion = false
    this.loadData()
  }

  editAudio (audio: RadioAudio) {
    this.currentAudioToEdit = audio
    this.dialogVisible = true
  }

  onlineAudio (audio: RadioAudio) {
    service.post(`/3l/radio/custom-menus/${this.radioMenuId}/audios/${audio.id}/online`).then((res: AxiosResponse) => {
      this.updateAudio(res.data)
    })
  }

  offlineAudio (audio: RadioAudio) {
    service.post(`/3l/radio/custom-menus/${this.radioMenuId}/audios/${audio.id}/offline`).then((res: AxiosResponse) => {
      if (this.isShowOnlineVersion) {
        this.audios = this.audios.filter(a => a.id !== audio.id)
      } else {
        this.updateAudio(res.data)
      }
    })
  }

  deleteAudio (audio: RadioAudio) {
    service.delete(`/3l/radio/custom-menus/${this.radioMenuId}/audios/${audio.id}`).then((res: AxiosResponse) => {
      this.audios = this.audios.filter(a => a.id !== audio.id)
    })
  }

  addAudio () {
    this.currentAudioToEdit = {
      name: '',
      menuId: this.radioMenuId,
      audio: '',
      sortNumber: 10,
      lightBackground: '',
      darkBackground: ''
    }
    this.dialogVisible = true
  }

  mounted () {
    this.loadData()
  }

  switchOnlineVersion () {
    this.isShowOnlineVersion = !this.isShowOnlineVersion
    this.loadData()
  }

  loadData () {
    service.get(`/3l/radio/custom-menus/${this.radioMenuId}/audios?online=${this.isShowOnlineVersion}`).then((res: AxiosResponse) => {
      this.audios = res.data
    })
  }
}
