
import { Options, Vue } from 'vue-class-component'
import { Model } from 'vue-property-decorator'

@Options({
  components: {},
  emits: ['confirm', 'cancel']
})
export default class PublishDialog extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  publishDialogVisible!: boolean

  commonComments = ['初始版本', '文案调整: ']

  formData = {
    comment: ''
  }

  publish () {
    (this.$refs.formDom as any).validate((valid: boolean) => {
      if (valid) {
        this.$emit('confirm', this.formData)
      } else {
        return false
      }
    })
  }

  cancel () {
    this.publishDialogVisible = false
    this.$emit('cancel')
  }
}
