
import { Options, Vue } from 'vue-class-component'
import { Model, Prop } from 'vue-property-decorator'

@Options({
  components: { },
  emits: ['search']
})
export default class SearchInput extends Vue {
  @Model('modelValue', {
    type: String,
    default: ''
  })
  keyword! : string

  @Prop({ default: '输入关键字进行搜索' })
  placeholder!: string

  search () {
    this.$emit('search', this.keyword)
  }
}
