
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Category, Tag } from '@/models/common'
import TagFilter from '@/components/TagFilter.vue'
import CategoryTree from '@/components/CategoryTree.vue'
import { Words } from '@/models/words'

@Options({
  components: {
    TagFilter,
    CategoryTree
  }
})
export default class WordsList extends Vue {
  filterTags: Tag[] = []
  currentSelectedCategories: Category[] = []
  currentCategoryId = 0
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<Words>()
  }

  beforeMount (): void {
    this.reloadPage(1)
  }

  createWords () {
    this.$router.push({ name: 'WordsEditor' })
  }

  reloadPage (pageNumber: number) {
    service.get('/3l/words', {
      params: {
        categoryIds: this.categoryIds,
        tagIds: this.filterTags.length > 0 ? this.filterTags.map(item => item.id).join(',') : undefined,
        pageNum: pageNumber,
        pageSize: 10
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  get categoryIds () {
    return this.currentSelectedCategories.length > 0 ? this.currentSelectedCategories.map(i => { return i.id }).join(',') : undefined
  }

  filterByTags (tags: Tag[]) {
    this.filterTags = tags
    this.reloadPage(1)
  }

  filterCategory (currentSelectedCategories: Category[]) {
    this.currentSelectedCategories = currentSelectedCategories
    this.reloadPage(1)
  }

  handleRowClick (words: Words) : void {
    this.$router.push({ name: 'WordsDetail', params: { id: words.id! } })
  }

  deleteWords (words: Words) {
    service.delete(`/3l/words/${words.id}`, {}).then(() => {
      this.tableData.totalCount--
      this.tableData.content = this.tableData.content.filter(item => { return item.id !== words.id })
    })
  }
}
