
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import service from '@/utils/service'
import { Card } from '@/models/card'

@Options({
  components: {},
  emits: ['card-removed', 'click-card']
})
export default class CropedCardList extends Vue {
  @Prop()
  fixWidth!: number

  @Prop()
  fixHeight!: number

  @Prop()
  cards!: Array<Card>

  removeCard (id: number) {
    service.delete(`/3l/cards/${id}`).then(() => {
      this.$emit('card-removed', id)
    })
  }
}
