import { Module, VuexModule, getModule, MutationAction } from 'vuex-module-decorators'
import store from '../index'
import service from '@/utils/service'
import { Tag } from '@/models/common'

@Module({
  name: 'tags',
  dynamic: true,
  namespaced: true,
  store
})
export default class TagStoreModule extends VuexModule {
  tags: Tag[] = []

  @MutationAction({ mutate: ['tags'] })
  async loadData () : Promise<any> {
    const response = await service.get('/3l/tags')
    return { tags: response.data.content }
  }

  get getTagById () {
    return (id: number) => {
      return this.tags.find(t => t.id === id) || { id: id, name: '未知标签' }
    }
  }

  get getTagByName () {
    return (name: string) => {
      return this.tags.find(t => t.name === name)
    }
  }
}

export const TagStore = getModule(TagStoreModule)
