
import { Options, Vue } from 'vue-class-component'
import CategoryTree from '@/components/CategoryTree.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import TagCreator from '@/components/TagCreator.vue'
import { Category, Tag } from '@/models/common'

function groupBy (list: any[], keyGetter: (item: any)=>any) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

@Options({
  components: {
    TagCreator,
    CategoryTree
  }
})
export default class TagList extends Vue {
  currentSelectedCategories: Category[] = []
  tableData: any[] = []

  beforeMount (): void {
    this.loadData()
  }

  tagCategoryUpdated (tag: Tag) {
    const categoryRow = this.tableData.find(c => c.category?.id === tag.category?.id)
    categoryRow && categoryRow.tags.push(tag)
  }

  loadData () {
    service.get('/3l/tags', {
      params: {
        categoryIds: this.currentSelectedCategories.length > 0 ? this.currentSelectedCategories.map(i => { return i.id }).join(',') : undefined
      }
    }).then((res: AxiosResponse) => {
      const map = groupBy(res.data.content, item => item.category?.id)
      if (this.currentSelectedCategories.length > 0 && !map.has(this.currentSelectedCategories[0].id)) {
        map.set(this.currentSelectedCategories[0].id, [])
      }
      this.tableData = Array.from(map.keys()).map(key => {
        const tags = map.get(key)
        return { category: this.currentSelectedCategories.find(i => i.id === key), tags }
      })
      if (this.tableData.length === 0) {
        // if (this.currentSelectedCategories.length > 0) {}
        this.tableData = this.currentSelectedCategories.map(item => { return { category: item, tags: [] } })
      }
    })
  }

  filterCategory (currentSelectedCategories: Category[]) {
    this.currentSelectedCategories = currentSelectedCategories
    this.loadData()
  }
}
