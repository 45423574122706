
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Card, CommonCardTagCategory } from '@/models/card'
import { TagStore } from '@/store/modules/tags'
import CardTagEditor from '@/components/CardTagEditor.vue'
import { ElMessage } from 'element-plus'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType, Tag } from '@/models/common'
import { Watch } from 'vue-property-decorator'

@Options({
  components: { CardTagEditor }
})
export default class AllCardList extends Vue {
  cards: Card[] = []
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<Card>()
  }

  commonCardTagCategories: CommonCardTagCategory[] = []
  pageNumber = 1
  currentPageSize = 10
  isBatchDelete = false
  tagsPanelVisible = false
  selectedCard?: Card
  originalImageTags: string[] = []
  filterTags: Tag[] = []
  batchTags: Tag[] = []
  batchTagEditorVisible = false
  tagsStatistics: any[] = []
  showTagsStatistics = false

  mounted () {
    TagStore.loadData()
    CategoryStore.loadData(CategoryType.TAG)
    service.get('/3l/cards/common-tag-categories').then((res: AxiosResponse<CommonCardTagCategory[]>) => {
      this.commonCardTagCategories = res.data
    })
    this.reloadPage(1)
  }

  @Watch('showTagsStatistics')
  onShowTagsStatisticsChanged () {
    if (this.showTagsStatistics) {
      service.get('/3l/cards/tags-statistics').then((res: AxiosResponse<any[]>) => {
        this.tagsStatistics = res.data
      })
    }
  }

  getCardCount (tagId: number) {
    if (!this.showTagsStatistics) {
      return undefined
    }
    return this.tagsStatistics.find(t => t.tagId === tagId)?.cardCount || 0
  }

  showBatchTagEditor () {
    this.isBatchDelete = false
    this.batchTagEditorVisible = true
    this.batchTags = []
  }

  batchTagConfirmed () {
    if (this.batchTags.length === 0) {
      ElMessage({ message: '至少选择一个标签', type: 'error', showClose: true })
      return
    }

    if (this.filterTags.length === 0) {
      ElMessage({ message: '至少筛选一个标签', type: 'error', showClose: true })
      return
    }

    service.post(`/3l/cards/${this.isBatchDelete ? 'batch-remove-tags' : 'batch-add-tags'}`, {
      tagIds: this.batchTags.map(t => t.id),
      filterTagIds: this.filterTags.map(t => t.id)
    }).then((res: AxiosResponse) => {
      if (this.isBatchDelete) {
        this.cards.forEach(card => { card.cardTags = card.cardTags.filter(ct => this.batchTags.findIndex(bt => bt.id === ct.tagId) < 0) })
      } else {
        this.cards.forEach(card => {
          this.batchTags.forEach(bt => {
            const cardTagIndex = card.cardTags.findIndex(ct => ct.tagId === bt.id)
            if (cardTagIndex < 0) {
              card.cardTags.push({ cardId: card.id, tagId: bt.id })
            }
          })
        })
      }
      this.batchTagEditorVisible = false
    })
  }

  handleSizeChange (size: number) {
    this.currentPageSize = size
    this.reloadPage(1)
  }

  reloadPage (pageNumber: number) {
    service.get('/3l/cards', {
      params: {
        pageNum: pageNumber,
        pageSize: this.currentPageSize,
        tagIds: this.filterTags.length > 0 ? this.filterTags.map(t => t.id).join(',') : undefined
      }
    }).then((res: AxiosResponse<any>) => {
      this.tableData = res.data
      this.cards = this.tableData.content
    })
  }

  editCard (card: Card) {
    this.selectedCard = card
    this.tagsPanelVisible = true
    service.get(`/node-crawlers/pexels-images/${card.pexelsImageId}/tags`, {
      data: {
        showLoading: false
      }
    }).then((res: any) => {
      this.originalImageTags = res
    })
  }

  isSelectedTag (tagId: number) {
    return this.filterTags.findIndex(ct => ct.id === tagId) > -1
  }

  clickTag (tag: Tag) {
    if (this.isSelectedTag(tag.id!)) {
      this.filterTags = this.filterTags.filter(ct => ct.id !== tag.id)
    } else {
      this.filterTags.push(tag)
    }

    this.$forceUpdate()
    this.reloadPage(1)
  }

  isSelectedBatchTag (tagId: number) {
    return this.batchTags.findIndex(ct => ct.id === tagId) > -1
  }

  clickBatchTag (tag: Tag) {
    if (this.isSelectedBatchTag(tag.id!)) {
      this.batchTags = this.batchTags.filter(ct => ct.id !== tag.id)
    } else {
      this.batchTags.push(tag)
    }

    this.$forceUpdate()
  }

  getTagName (tagId: number) {
    return TagStore.getTagById(tagId).name
  }

  removeCard (id: number) {
    service.delete(`/3l/cards/${id}`).then(() => {
      this.cards.splice(this.cards.findIndex(c => c.id === id), 1)
      ElMessage({ message: '删除成功', type: 'success', showClose: true })
    })
  }
}
