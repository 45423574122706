
import { Options, Vue } from 'vue-class-component'
import { MindUpMatchRule, MindUpPractice } from '@/models/mindup'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import DemoList from '@/components/DemoList.vue'
import { TagStore } from '@/store/modules/tags'
import { Demo } from '@/models/demo'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'

@Options({
  components: { DemoList },
  emits: ['success', 'cancel']
})
export default class MindUpMatchAudioTable extends Vue {
  @Prop()
  practice!: MindUpPractice

  @Prop({ default: false })
  isEditing!: boolean

  currentQueryParams: any
  currentAudio: any

  @Prop()
  mindUpMatchRule!: MindUpMatchRule

  demoSelectedDialogVisible = false

  get categoryPath () {
    return this.mindUpMatchRule.categoryId ? CategoryStore.findParentAndSelf(this.mindUpMatchRule.categoryId!, CategoryType.DEMO) : []
  }

  autoMatch () {
    this.practice.audios.forEach(audio => {
      service.get('/3l/demos', {
        params: {
          categoryIds: this.categoryIds,
          tagIds: [...new Set([...this.mindUpMatchRule.tagIds || [], ...this.practice.matchTagIds, ...audio.matchTagIds])].join(','),
          published: true,
          pageNum: 1,
          pageSize: 10
        }
      }).then((res: AxiosResponse) => {
        if (res.data.content.length === 1) {
          const demo = res.data.content[0]
          this.updateAudioDemoInfo(audio, demo)
        }
      })
    })
  }

  private updateAudioDemoInfo (audio: any, demo: Demo) {
    audio.audio = demo.demoUrl
    audio.duration = demo.duration
    audio.fileSize = demo.fileSize
    audio.demoName = demo.name
    audio.demoId = demo.id
    audio.demoVersion = demo.latestVersion
  }

  get categoryIds () {
    if (this.mindUpMatchRule.categoryId) {
      return [this.mindUpMatchRule.categoryId!, ...CategoryStore.getAllChildrenById(this.mindUpMatchRule.categoryId!, CategoryType.DEMO)].join(',')
    }
    return undefined
  }

  cancel () {
    this.$emit('cancel')
  }

  submit () {
    service.post(`/3l/mind-up-practices/${this.practice.id}/versions/${this.practice.latestVersion}/audios`, this.practice.audios).then((res: AxiosResponse) => {
      this.$emit('success', res.data)
    })
  }

  selectDemo (audio: any) {
    this.currentAudio = audio
    this.currentQueryParams = {
      categoryId: this.mindUpMatchRule.categoryId,
      filterTags: [...new Set([...this.mindUpMatchRule.tagIds || [], ...this.practice.matchTagIds, ...audio.matchTagIds])].map(id => TagStore.getTagById(id))
    }
    this.demoSelectedDialogVisible = true
  }

  demoSelected (demo: Demo) {
    this.updateAudioDemoInfo(this.currentAudio, demo)
    this.demoSelectedDialogVisible = false
    console.log(this.currentAudio)
  }

  @Watch('practice')
  onPracticeChanged (practice: MindUpPractice) {
    if (!practice.options || practice.options.length === 0) {
      return
    }

    function handleOption (result: any, option: any) {
      result[option.code] = option.tag
      result.options.push({
        code: option.code,
        name: option.name,
        tagId: option.tag.id,
        tagName: option.tag.name
      })
      result.matchTagIds.push(option.tag.id)
    }

    practice.audios = this.calcOptionMatrix(practice).map((combination: any) => {
      const result: any = { matchTagIds: new Array<number>(), options: new Array<any>() }
      if (combination instanceof Array) {
        combination.forEach(option => {
          handleOption(result, option)
        })
      } else {
        handleOption(result, combination)
      }
      result.matchTagIds.sort()
      result.matchTagIdsKey = result.matchTagIds.join(',')
      return result
    })

    service.get(`/3l/mind-up-practices/${this.practice.id}/versions/${this.practice.latestVersion !== undefined ? this.practice.latestVersion : this.practice.version}/audios`).then((res: AxiosResponse<any[]>) => {
      const audios = res.data
      practice.audios.forEach(audio => {
        const serverSideAudio = audios.find(a => a.matchTagIdsKey === audio.matchTagIdsKey)
        if (serverSideAudio) {
          audio.audio = serverSideAudio.audio
          audio.duration = serverSideAudio.duration
          audio.fileSize = serverSideAudio.fileSize
          audio.demoId = serverSideAudio.demoId
          audio.demoName = serverSideAudio.demoName
          audio.demoVersion = serverSideAudio.demoVersion
        }
      })
    })
  }

  mounted () {
    this.onPracticeChanged(this.practice)
  }

  private calcOptionMatrix (practice: MindUpPractice) {
    const options = practice.options.map(o => this.mindUpMatchRule.options!.find(op => op.code === o.code))
    const optionArr = options.map(option => {
      return [{
        code: option.code,
        name: option.name,
        tag: option.defaultTag
      }, {
        code: option.code,
        name: option.name,
        tag: option.nonDefaultTag
      }]
    })

    return optionArr.reduce((last, current) => {
      const array = new Array<any>()
      last.forEach(par1 => {
        current.forEach(par2 => {
          if (par1 instanceof Array) {
            array.push([...par1, par2])
          } else {
            array.push([par1, par2])
          }
        })
      })
      return array
    })
  }
}
