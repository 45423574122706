
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CommonCardTagCategory } from '@/models/card'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { TagStore } from '@/store/modules/tags'
import CardSetAssemblyView from '@/components/CardSetAssemblyView.vue'

@Options({
  components: { CardSetAssemblyView },
  emits: ['success', 'cancel']
})
export default class CardSetAssemblyList extends Vue {
  @Prop()
  cardSetId!: number

  cardSetAssemblies: any[] = []

  viewDialogVisible = false

  currentAssemblyId = 0

  @Watch('cardSetId')
  onCardSetIdChanged () {
    this.loadData()
  }

  getTag (tagId: any) {
    return TagStore.getTagById(Number.parseInt(tagId))
  }

  mounted () {
    this.loadData()
  }

  deleteAssembly (id: number) {
    service.delete(`/3l/card-set-assemblies/${id}`).then((res: AxiosResponse<CommonCardTagCategory[]>) => {
      this.cardSetAssemblies = this.cardSetAssemblies.filter(a => a.id !== id)
    })
  }

  publish (assembly: any, published: boolean) {
    service.post(`/3l/card-set-assemblies/${assembly.id}/${published ? 'publish' : 'unpublish'}`).then((res: AxiosResponse) => {
      assembly.published = published
    })
  }

  syncToCMS (assembly: any) {
    service.post(`/3l/card-set-assemblies/${assembly.id}/sync-to-cms`).then((res: AxiosResponse) => {
      assembly.cmsId = res.data.cmsId
    })
  }

  loadData () {
    if (!this.cardSetId) {
      return
    }

    service.get('/3l/card-set-assemblies', {
      params: {
        cardSetId: this.cardSetId
      }
    }).then((res: AxiosResponse<CommonCardTagCategory[]>) => {
      this.cardSetAssemblies = res.data
    })
  }

  view (row: any) {
    this.currentAssemblyId = row.id
    this.viewDialogVisible = true
  }
}
