import { Module, VuexModule, Mutation, Action, getModule, MutationAction } from 'vuex-module-decorators'
import store from '../index'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { TimeBox } from '@/models/radio'
import dayjs from 'dayjs'
import { MindUpMatchRule } from '@/models/mindup'

const getTotalMinutes = (timeStr: string) => {
  const timeSegments = timeStr.split(':')
  const hour = Number.parseInt(timeSegments[0])
  const minute = Number.parseInt(timeSegments[1])
  const totalMinutes = hour * 60 + minute
  return totalMinutes
}

@Module({
  name: 'radio',
  dynamic: true,
  namespaced: true,
  store
})
export default class RadioModule extends VuexModule {
  private _timeBoxes: TimeBox[] = []
  private _mindUpMatchRule: MindUpMatchRule = {}

  configuredDays: string[] = []

  get timeBoxes (): TimeBox[] {
    return JSON.parse(JSON.stringify(this._timeBoxes))
  }

  get mindUpMatchRule (): MindUpMatchRule {
    return this._mindUpMatchRule
  }

  @Action
  loadTimeBoxes () {
    return service.get('/3l/radio/configs/time-boxes').then((res: AxiosResponse) => {
      this.timeBoxesUpdated(res.data)
      return this.timeBoxes
    })
  }

  @Action
  loadMindUpMatchRule () {
    return service.get('/3l/radio/configs/mind-up-match-rule').then((res: AxiosResponse) => {
      this.mindUpMatchRuleUpdated(res.data)
      return this.mindUpMatchRule
    })
  }

  @Mutation
  timeBoxesUpdated (timeBoxes: TimeBox[]) {
    this._timeBoxes = timeBoxes
  }

  @Mutation
  mindUpMatchRuleUpdated (mindUpMatchRule: any) {
    this._mindUpMatchRule = mindUpMatchRule
  }

  @Action
  updateTimeBoxes (timeBoxes: TimeBox[]) {
    return service.post('/3l/radio/configs/time-boxes', timeBoxes).then((res: AxiosResponse) => {
      this.timeBoxesUpdated(res.data)
      return this.timeBoxes
    })
  }

  @MutationAction({ mutate: ['configuredDays'] })
  loadConfiguredDays (date: Date): Promise<any> {
    return service.get('/3l/radio/calendar/days', {
      params: {
        endDay: dayjs(date).add(42, 'day').format('YYYY-MM-DD'),
        startDay: dayjs(date).add(-42, 'day').format('YYYY-MM-DD')
      }
    }).then((res: AxiosResponse) => {
      return { configuredDays: res.data }
    })
  }

  get getTotalMinutes () {
    return getTotalMinutes
  }

  get calcTimePoint () {
    return (timeStr: string, addedMinutes: number): string => {
      const totalMinutes = getTotalMinutes(timeStr) + addedMinutes
      const finalMinutes = totalMinutes % 60
      const finalHours = (totalMinutes - finalMinutes) / 60
      return `00${finalHours}`.slice(-2) + ':' + `00${finalMinutes}`.slice(-2)
    }
  }

  @Action
  updateMindUpMatchRule (configData: any) {
    return service.post('/3l/radio/configs/mind-up-match-rule', configData).then((res: AxiosResponse) => {
      this.mindUpMatchRuleUpdated(res.data)
      return this.mindUpMatchRule
    })
  }
}

export const RadioStore = getModule(RadioModule)
