
import { Options, Vue } from 'vue-class-component'
import { DemoSlice } from '@/models/demo'
import { AxiosResponse } from 'axios'
import service from '@/utils/service'
import { Prop, Watch } from 'vue-property-decorator'

@Options({
  components: { }
})
export default class DemoSlices extends Vue {
  @Prop()
  slices: DemoSlice[] = []

  getTitle (slot: DemoSlice) {
    if (slot.slotId === -1) {
      return '结束'
    }

    return slot.slotName
  }
}
