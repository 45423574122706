
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { CardKeyword } from '@/models/card'
import { ElMessage } from 'element-plus'
import CardKeywordCreateDialog from '@/components/CardKeywordCreateDialog.vue'
import { TagStore } from '@/store/modules/tags'
import ImageUploader from '@/components/ImageUploader.vue'
import { UploadFile } from 'element-plus/es/el-upload/src/upload.type'
import { Watch } from 'vue-property-decorator'
import { RadioMenu } from '@/models/radio'
import { ImagesStore } from '@/store/modules/images'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {
    CardKeywordCreateDialog,
    ImageUploader
  }
})
export default class CardKeywordList extends Vue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/library/books/pdfs`
  tokenHeader = { Authorization: AuthStore.token + '' }
  showUploading = false
  createDialogVisible = false
  currentUploadKeywordId = 0
  fileList: any[] = []
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<CardKeyword>()
  }

  currentRowId = 0

  keywordCreated (cardKeyword: CardKeyword) {
    ElMessage({
      message: '添加成功',
      type: 'success',
      showClose: true
    })
    this.createDialogVisible = false
    const existKeywordIndex = this.tableData.content.findIndex(item => cardKeyword.id === item.id)
    if (existKeywordIndex > -1) {
      this.tableData.content.splice(existKeywordIndex, 1, cardKeyword)
    } else {
      this.tableData.content.splice(0, 0, cardKeyword)
      this.tableData.count++
      this.tableData.totalCount++
    }
  }

  imageUploaded (file: any) {
    this.$router.push({
      name: 'Crop',
      params: {
        id: this.currentUploadKeywordId,
        image: JSON.stringify(file)
      }
    })
  }

  mounted () {
    TagStore.loadData()
    setInterval(() => {
      this.tableData.content.forEach((keyword, index) => {
        if (keyword.progressStatus === 'FETCHING_IMAGES') {
          service.get(`/3l/cards/keywords/${keyword.id}`, {
            data: {
              showLoading: false
            }
          }).then((res: AxiosResponse) => {
            this.tableData.content.splice(index, 1, res.data)
          })
        }
      })
    }, 5000)
  }

  beforeUnmount () {
    this.tableData.content = []
  }

  gotoCardList (cardKeyword: CardKeyword) {
    this.$router.push({
      name: 'KeywordCardList',
      params: { id: cardKeyword.id }
    })
  }

  beforeMount (): void {
    this.reloadPage(1)
  }

  reloadPage (pageNumber: number) {
    service.get('/3l/cards/keywords', {
      params: {
        withStatus: true,
        pageNum: pageNumber,
        pageSize: 10
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  cellClick (row: any, column: any, cell: any, event: any) {
    if (column.label === '初选' && row.progressStatus === 'FETCH_IMAGES_DONE') {
      this.$router.push({
        name: 'PreSelect',
        params: { id: row.id }
      })
    }
    if (column.label === '切图' && row.selectedCount > 0) {
      this.$router.push({
        name: 'Crop',
        params: { id: row.id }
      })
    }
  }

  deleteKeyword (cardKeyword: CardKeyword) {
    service.delete(`/3l/cards/keywords/${cardKeyword.id}`, {}).then(() => {
      this.tableData.totalCount--
      this.tableData.content = this.tableData.content.filter(item => {
        return item.id !== cardKeyword.id
      })
    })
  }

  handleImageUploaded (e: any) {
    const obj = JSON.parse(JSON.stringify(e))
    const resImages = obj.map((t: any) => {
      return t.response.data
    })
    ImagesStore.setImages(resImages)
    this.$router.push({
      name: 'Crop',
      params: {
        id: this.currentUploadKeywordId,
        images: 'true'
      }
    })
  }
}
