
import { Options, Vue } from 'vue-class-component'
import { CategoryType, Tag } from '@/models/common'
import CategoryTree from '@/components/CategoryTree.vue'
import TagInput from '@/components/TagInput.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Model, Prop } from 'vue-property-decorator'
import { ElMessageBox } from 'element-plus'
import { CategoryStore } from '@/store/modules/category'

@Options({
  components: {
    TagInput,
    CategoryTree
  },
  emits: ['tag-category-updated']
})
export default class TagCreator extends Vue {
  @Model('modelValue', {
    type: Array,
    default: []
  })
  currentTags!: Tag[]

  editDialogVisible = false
  currentTagName = ''
  currentTagCategoryIds: number[] = []
  currentTag!: Tag

  @Prop() categoryId?: number

  inputValue = ''

  handleClose (tag: Tag) {
    ElMessageBox.confirm('将删除所有对象上的该标签, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      service.delete(`/3l/tags/${tag.id!}`).then(() => {
        this.currentTags.splice(this.currentTags.indexOf(tag), 1)
      })
    })
  }

  get tagCategories () {
    return CategoryStore.tagCategories
  }

  showTagNameEditor (tag: Tag) {
    this.currentTagName = tag.name
    this.currentTagCategoryIds = CategoryStore.findParentAndSelf(tag.categoryId!, CategoryType.TAG).map(item => {
      return item.id!
    })
    this.currentTag = tag
    this.editDialogVisible = true
  }

  updateTagName () {
    service.post(`/3l/tags/${this.currentTag.id}`, { name: this.currentTagName, categoryId: this.currentTagCategoryIds.slice(-1)[0] }).then((res: AxiosResponse) => {
      this.currentTag.name = res.data.name
      if (this.currentTag.categoryId !== res.data.categoryId) {
        this.currentTags.splice(this.currentTags.indexOf(this.currentTag), 1)
        this.currentTag.categoryId = res.data.categoryId
        this.currentTag.category = res.data.category
        this.$emit('tag-category-updated', this.currentTag)
      }
      this.editDialogVisible = false
    })
  }

  handleInputConfirm () {
    if (this.inputValue) {
      this.createTag({ name: this.inputValue, categoryId: this.categoryId })
    }

    this.reset()
  }

  createTag (tag: Tag) {
    service.post('/3l/tags', tag).then((res: AxiosResponse) => {
      this.currentTags.push(res.data)
    })
  }

  reset (): void {
    this.inputValue = ''
  }
}
