
import { Options, Vue } from 'vue-class-component'
import TagInput from '@/components/TagInput.vue'
import { Tag } from '@/models/common'
import { Model, Prop } from 'vue-property-decorator'

@Options({
  components: { TagInput },
  emits: ['search']
})
export default class TagFilter extends Vue {
  @Model('modelValue', {
    default: []
  })
  filterTags!: Tag[]
}
