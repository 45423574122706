
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import { Template } from '@/models/demo'
import TemplateForm from '@/components/TemplateForm.vue'

@Options({
  components: { TemplateForm }
})
export default class TemplateBasic extends Vue {
  template: Template = {
    name: ''
  }

  beforeMount () {
    CategoryStore.loadData(CategoryType.SLICE).then(() => {
      service.get(`/3l/templates/${this.$route.params.id}`).then((res: AxiosResponse) => {
        this.template = res.data
      })
    })
  }

  goBack () {
    this.$router.push({ name: 'TemplateList' })
  }
}
