
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryType, Category, Tag } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { TemplateSlot } from '@/models/demo'
import { LatestSlice } from '@/models/slice'
import TagInput from '@/components/TagInput.vue'
import SliceSelector from '@/components/SliceSelector.vue'

@Options({
  components: { TagInput, SliceSelector },
  emits: ['success', 'cancel', 'deleted', 'add-slot', 'slot-slice-selected']
})
export default class SlotForm extends Vue {
  @Prop()
  templateId!: number

  @Prop()
  slotSliceMap!: Map<number, LatestSlice>

  sliceDialogVisible = false

  @Prop({ default: true })
  editable!: boolean

  @Prop()
  sortNumber!: number

  @Prop({ default: true })
  showDelete!: boolean

  @Prop({
    default: {
      name: '',
      tags: Array<Tag>(),
      categoryIds: Array<number>()
    }
  })
  slotData!: TemplateSlot

  isEditing = true

  get sliceCategories () {
    return CategoryStore.sliceCategories
  }

  handleSliceSelected (data: any) {
    this.slotSliceMap.set(data.slotId, data.slice)
  }

  get audio () {
    return this.slotSliceMap.get(this.slotData.id!)?.audio
  }

  get words () {
    return this.slotSliceMap.get(this.slotData.id!)?.words
  }

  currentSlot = this.slotData
  categoryPath: Category[] = []

  formData = {
    ...this.currentSlot,
    categoryIds: Array<number>()
  }

  @Watch('slotData', { immediate: true })
  onSlotChanged (slot: TemplateSlot) {
    this.currentSlot = slot
    this.isEditing = !slot.id
    if (!this.currentSlot.matchCategoryId) {
      this.categoryPath = [{
        parentId: 0,
        code: '未分类',
        type: CategoryType.DEMO,
        name: '未分类'
      }]
      this.formData.categoryIds = []
    } else {
      this.categoryPath = CategoryStore.findParentAndSelf(this.currentSlot.matchCategoryId!, CategoryType.SLICE)
      this.formData.categoryIds = this.categoryPath.map(item => item.id!)
    }
  }

  cancel () {
    this.formData = JSON.parse(JSON.stringify({
      ...this.currentSlot,
      categoryIds: this.categoryPath.map(item => item.id!)
    }))
    this.isEditing = false
    this.$emit('cancel')
  }

  deleteSlot (slot: TemplateSlot) {
    if (this.currentSlot.id) {
      service.delete(`/3l/templates/${this.templateId}/slots/${this.currentSlot.id}`, {}).then(() => {
        this.$emit('deleted', slot)
      })
    } else {
      this.$emit('deleted', slot)
    }
  }

  onSubmit () {
    (this.$refs.formDom as any).validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          matchCategoryId: this.formData.categoryIds?.length > 0 ? this.formData.categoryIds.slice(-1)[0] : undefined,
          sortNumber: this.sortNumber,
          templateId: this.templateId
        }

        let uri = `/3l/templates/${this.templateId}/slots`
        if (this.slotData.id) {
          uri = `${uri}/${this.slotData.id}`
        }

        service.post(uri, data).then((res: AxiosResponse) => {
          this.currentSlot = res.data
          this.isEditing = false
          this.$emit('success', { index: this.sortNumber, slot: res.data })
        })
      } else {
        return false
      }
    })
  }
}
