
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Watch } from 'vue-property-decorator'
import CardSteps from '@/components/CardSteps.vue'
import { ElMessageBox } from 'element-plus'
import { CardImage, PageStatus } from '@/models/card'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

@Options({
  components: { CardSteps }
})
export default class PreSelect extends Vue {
  currPageNum = 1
  infoPanelVisible = false
  columns = Number.parseInt(localStorage.getItem('image-columns') || '4')
  pageStatus: Array<PageStatus> = []

  images: CardImage[] = []

  get id () {
    return this.$route.params.id
  }

  @Watch('columns')
  onColumnsChanged (value: number) {
    localStorage.setItem('image-columns', value + '')
  }

  beforeRouteLeave (to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext) {
    this.askForComplete(next)
  }

  askForComplete (callback: any) {
    if (this.currentPage.status !== 'PRE_SELECTED_DONE') {
      ElMessageBox.confirm('是否将当前页标记为完成?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            service.post(`/3l/cards/keywords/${this.id}/page-status/${this.currPageNum}/status`, {
              status: 'PRE_SELECTED_DONE'
            }).then(() => {
              this.currentPage.status = 'PRE_SELECTED_DONE'
            }).finally(() => {
              done()
              callback()
            })
          } else {
            done()
            callback()
          }
        }
      }).catch(() => {
        //
      })
    } else {
      callback()
    }
  }

  changePage (pageNum: number) {
    if (this.currPageNum === pageNum) {
      return
    }

    this.askForComplete(() => { this.reloadPage(pageNum) })
  }

  get currentPage () {
    return this.pageStatus.find(p => p.pageNum === this.currPageNum)!
  }

  mounted () {
    this.reloadPage(1)
  }

  selectImage (image: CardImage) {
    service.post(`/3l/cards/keywords/${this.id}/images/${image.pexelsImageId}/selection`, {
      selected: !image.selected,
      showLoading: false
    }).then(() => {
      image.selected = !image.selected

      if (image.selected) {
        this.currentPage.selectedCount++
      } else {
        this.currentPage.selectedCount--
      }
    })
  }

  reloadPage (pageNumber: number) {
    this.currPageNum = pageNumber

    service.get(`/3l/cards/keywords/${this.id}/page-status`).then((res: AxiosResponse) => {
      this.pageStatus = res.data
    })

    service.get(`/3l/cards/keywords/${this.id}/images`, {
      params: {
        pageNum: pageNumber
      }
    }).then((res: AxiosResponse) => {
      this.images = res.data
    })
  }
}
