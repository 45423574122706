
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Card, CardKeyword } from '@/models/card'
import { TagStore } from '@/store/modules/tags'
import CardTagEditor from '@/components/CardTagEditor.vue'
import { ElMessage } from 'element-plus'

@Options({
  components: { CardTagEditor }
})
export default class KeywordCardList extends Vue {
  cards: Card[] = []
  tagsPanelVisible = false
  selectedCard?: Card
  originalImageTags: string[] = []
  cardKeyword?: CardKeyword
  keyword = ''

  mounted () {
    TagStore.loadData()
    service.get(`/3l/cards/keywords/${this.id}/cards`).then((res: AxiosResponse<any[]>) => {
      this.cards = res.data
    })

    service.get(`/3l/cards/keywords/${this.id}`).then((res: AxiosResponse) => {
      this.cardKeyword = res.data
      this.keyword = this.cardKeyword?.keyword || ''
    })
  }

  clickCard (card: Card) {
    this.selectedCard = card
    this.tagsPanelVisible = true
    service.get(`/node-crawlers/pexels-images/${card.pexelsImageId}/tags`).then((res: any) => {
      this.originalImageTags = res
    })
  }

  getTagName (tagId : number) {
    return TagStore.getTagById(tagId).name
  }

  removeCard (id: number) {
    service.delete(`/3l/cards/${id}`).then(() => {
      this.cards.splice(this.cards.findIndex(c => c.id === id), 1)
      ElMessage({ message: '删除成功', type: 'success', showClose: true })
    })
  }

  goBack () {
    this.$router.back()
  }

  get id () {
    return this.$route.params.id
  }
}
