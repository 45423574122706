
import { Options, Vue } from 'vue-class-component'
import { Slice } from '@/models/slice'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import SliceForm from '@/components/SliceForm.vue'

@Options({
  components: { SliceForm }
})
export default class SliceBasic extends Vue {
  slice: Slice = {
    id: 0,
    name: '',
    words: '',
    audio: '',
    duration: 0,
    fileSize: 0,
    author: undefined,
    tags: []
  }

  beforeMount () {
    CategoryStore.loadData(CategoryType.SLICE).then(() => {
      service.get(`/3l/slices/${this.$route.params.id}`).then((res: AxiosResponse) => {
        this.slice = res.data
      })
    })
  }

  goBack () {
    this.$router.push({ name: 'SliceList' })
  }
}
