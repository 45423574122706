
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { CardSetAssembly } from '@/models/card'
import { TagStore } from '@/store/modules/tags'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import TagInput from '@/components/TagInput.vue'

@Options({
  components: { TagInput },
  emits: ['success', 'cancel']
})
export default class CardSetAssemblyForm extends Vue {
  @Prop({
    default: {
      name: '',
      tags: [],
      parts: [],
      description: ''
    }
  })
  cardSetAssembly!: CardSetAssembly

  editing = false

  formData = {
    ...this.cardSetAssembly
  }

  getTag (tagId: number) {
    return TagStore.getTagById(tagId)
  }

  cancel () {
    this.editing = false
    this.$emit('cancel')
  }

  onSubmit () {
    const form = (this.$refs.form as any)
    form.validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          tagIds: this.formData.tags.map(t => t.id)
        }
        let url = '/3l/card-set-assemblies'
        if (this.cardSetAssembly.id) {
          url += '/' + this.cardSetAssembly.id
        }
        service.post(url, data).then((res: AxiosResponse) => {
          (this.$refs.form as any).resetFields()
          this.$emit('success', res.data)
        })
      }
    })
  }
}
