
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { CategoryType, Category, Tag } from '@/models/common'
import TagFilter from '@/components/TagFilter.vue'
import CategoryTree from '@/components/CategoryTree.vue'
import { Demo } from '@/models/demo'
import { Prop, Watch } from 'vue-property-decorator'
import PublishDialog from '@/components/PublishDialog.vue'
import DemoForm from '@/components/DemoForm.vue'
import { CategoryStore } from '@/store/modules/category'

@Options({
  components: {
    TagFilter,
    CategoryTree,
    DemoForm,
    PublishDialog
  },
  emits: ['demo-selected']
})
export default class DemoList extends Vue {
  @Prop({ default: false })
  isSelectorMode! : boolean

  @Prop({ default: { filterTags: [] } })
  queryParams! : { categoryId? : number, filterTags: Tag[] }

  publishDialogVisible = false
  demoDialogVisible = false
  currentSelectedDemo!: Demo
  searchName = ''

  uploadDemo () {
    this.demo = {
      name: '',
      templateId: undefined,
      demoUrl: '',
      duration: 0,
      fileSize: 0,
      categoryId: this.currentCategoryId,
      slices: [],
      tags: []
    }
    this.demoDialogVisible = true
  }

  filterTags: Tag[] = this.queryParams.filterTags
  currentCategoryId = this.queryParams.categoryId
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<Demo>()
  }

  demo: Demo = {
    name: '',
    templateId: undefined,
    demoUrl: '',
    duration: 0,
    fileSize: 0,
    categoryId: this.currentCategoryId,
    slices: [],
    tags: []
  }

  @Watch('queryParams')
  onQueryParamsChanged () {
    this.filterTags = this.queryParams.filterTags
    this.currentCategoryId = this.queryParams.categoryId
    this.reloadPage(1)
  }

  publish (data: any) {
    service.post(`/3l/demos/${this.currentSelectedDemo.id!}/versions`, data).then((res: AxiosResponse) => {
      this.publishDialogVisible = false
      const index = this.tableData.content.findIndex(d => d.id === this.currentSelectedDemo.id!)
      this.tableData.content.splice(index, 1, res.data)
    })
  }

  demoCreated (demo: Demo) {
    this.demoDialogVisible = false
    this.currentCategoryId = demo.categoryId!
    this.reloadPage(1)
  }

  openDialogForPublish (demo: Demo) {
    this.currentSelectedDemo = demo
    this.publishDialogVisible = true
  }

  beforeMount (): void {
    this.reloadPage(1)
  }

  reloadPage (pageNumber: number) {
    service.get('/3l/demos', {
      params: {
        categoryIds: this.getCategoryIds(),
        name: this.searchName.trim().length > 0 ? this.searchName.trim() : undefined,
        tagIds: this.filterTags.length > 0 ? this.filterTags.map(item => item.id).join(',') : undefined,
        published: this.isSelectorMode,
        pageNum: pageNumber,
        pageSize: 10
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  getCategoryIds () {
    if (this.currentCategoryId) {
      return [this.currentCategoryId, ...CategoryStore.getAllChildrenById(this.currentCategoryId!, CategoryType.DEMO)].join(',')
    }
    return undefined
  }

  search () {
    this.reloadPage(1)
  }

  filterCategory (currentSelectedCategories: Category[]) {
    this.currentCategoryId = currentSelectedCategories.length > 0 ? currentSelectedCategories[0].id! : undefined
    this.reloadPage(1)
  }

  viewStructure (demo: Demo) : void {
    if (this.isSelectorMode) {
      this.$emit('demo-selected', demo)
    } else {
      this.$router.push({ name: 'DemoStructure', params: { id: demo.id! } })
    }
  }

  edit (demo: Demo) : void {
    if (this.isSelectorMode) {
      this.$emit('demo-selected', demo)
    } else {
      this.$router.push({ name: 'DemoBasic', params: { id: demo.id! } })
    }
  }

  deleteDemo (demo: Demo) {
    service.delete(`/3l/demos/${demo.id}`, {}).then(() => {
      this.tableData.totalCount--
      this.tableData.content = this.tableData.content.filter(item => { return item.id !== demo.id })
    })
  }
}
