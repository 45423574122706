import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '../index'
import { getPayload } from '@/utils/jwt'
import { User } from '../../models/user'

@Module({
  name: 'auth',
  dynamic: true,
  namespaced: true,
  store
})
export default class AuthStoreModule extends VuexModule {
  private _token?: string
  private _user?: User

  get token (): string | undefined {
    if (!this._token) {
      const tokenStr = localStorage.getItem('token')
      if (tokenStr) {
        this._token = tokenStr
      }
    }
    return this._token
  }

  get isTokenValid (): boolean {
    return !!this.user && (this.user.exp * 1000 > Date.now())
  }

  get user (): User | undefined {
    if (!this._user) {
      const userStr = localStorage.getItem('user')
      if (userStr) {
        this._user = JSON.parse(userStr) as User
      }
    }
    return this._user
  }

  get avatar (): string {
    if (this.user) {
      return this.user.avatar
    }
    return ''
  }

  get userId (): string {
    if (this.user) {
      return this.user.userId
    }
    return ''
  }

  get username (): string {
    if (this.user) {
      return this.user.username
    }
    return ''
  }

  get appCode (): string {
    if (this.user) {
      return this.user.appCode
    }
    return ''
  }

  @Mutation
  setToken (value: string) {
    const payload = getPayload(value)
    this._token = value
    this._user = JSON.parse(payload)

    localStorage.setItem('token', value)
    localStorage.setItem('user', payload)
  }

  @Mutation
  removeToken () {
    this._token = undefined
    this._user = undefined
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }
}

export const AuthStore = getModule(AuthStoreModule)
