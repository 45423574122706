
import { Options, Vue } from 'vue-class-component'
import { ElMessage } from 'element-plus'
import { Model, Watch } from 'vue-property-decorator'
import { MindUpOption, MindUpOptions } from '@/models/mindup'

@Options({
  components: {},
  emits: ['success', 'cancel', 'changed']
})
export default class MindUpOptionsInput extends Vue {
  @Model('modelValue', {
    type: Array,
    default: []
  })
  dynamicOptions!: MindUpOption[]

  inputValue = ''

  handleClose (option: MindUpOption) {
    this.dynamicOptions.splice(this.dynamicOptions.indexOf(option), 1)
  }

  querySearch (queryString: string, cb: (results: any) => void) {
    const leftOptions = MindUpOptions.filter(o => this.dynamicOptions.findIndex(i => i.code === o.code) < 0)
    const results = queryString
      ? leftOptions.filter(this.createFilter(queryString))
      : leftOptions
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter (queryString: string): (results: any) => void {
    return (option) => {
      return option.name.toLowerCase().indexOf(queryString.toLowerCase()) > -1
    }
  }

  handleSelect (option: MindUpOption): void {
    this.dynamicOptions.push(option)
    this.resetInput()
  }

  @Watch('dynamicOptions', { deep: true, immediate: true })
  onTagChanged () : void {
    this.$emit('changed')
  }

  handleInputConfirm () {
    if (this.inputValue) {
      const option = MindUpOptions.find(option => option.name === this.inputValue)
      if (!option) {
        ElMessage({ message: '选项不存在', type: 'warning', showClose: true })
        this.resetInput()
        return
      }

      if (this.dynamicOptions.find(item => item.name === option.name)) {
        ElMessage({ message: '请勿输入重复选项', type: 'warning', showClose: true })
        this.resetInput()
      } else {
        this.dynamicOptions.push(option)
        this.resetInput()
      }
    }
  }

  resetInput () : void {
    this.inputValue = ''
  }
}
