
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { EditMode } from '@/models/common'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { MindUpPractice } from '@/models/mindup'
import MindUpOptionsInput from '@/components/MindUpOptionsInput.vue'
import { TagStore } from '@/store/modules/tags'
import TagInput from '@/components/TagInput.vue'
import ImagePicker from '@/components/ImagePicker.vue'
import ImageUploader from '@/components/ImageUploader.vue'

@Options({
  components: { MindUpOptionsInput, TagInput, ImagePicker, ImageUploader },
  emits: ['success', 'cancel']
})
export default class MindUpPracticeForm extends Vue {
  @Prop()
  categoryId!: number

  editMode = EditMode.ADD

  @Prop()
  mindUpPractice! : MindUpPractice

  currentPractice : MindUpPractice = JSON.parse(JSON.stringify(this.mindUpPractice))

  formData = this.getFormData()

  showLightBackgroundUploading = false
  showDarkBackgroundUploading = false
  imageDialogVisible = false
  currentOperateVisualMode = 'LIGHT'

  selectForLightBackground () {
    this.currentOperateVisualMode = 'LIGHT'
    this.imageDialogVisible = true
  }

  selectForDarkBackground () {
    this.currentOperateVisualMode = 'DARK'
    this.imageDialogVisible = true
  }

  handleLightBackgroundUploaded (file: any) {
    this.formData.lightBackground = file.url
  }

  handleDarkBackgroundUploaded (file: any) {
    this.formData.darkBackground = file.url
  }

  handleImageSelected (image: any) {
    if (this.currentOperateVisualMode === 'LIGHT') {
      this.formData.lightBackground = image.photoModalImageSrc
    } else {
      this.formData.darkBackground = image.photoModalImageSrc
    }
    this.imageDialogVisible = false
  }

  getFormData () {
    return {
      ...this.currentPractice,
      matchTags: this.currentPractice.matchTagIds.map(id => TagStore.getTagById(id))
    }
  }

  @Watch('mindUpPractice', { immediate: true })
  onPracticeChanged (mindUpPractice: MindUpPractice) {
    this.editMode = mindUpPractice.id ? EditMode.MODIFY : EditMode.ADD
    this.currentPractice = JSON.parse(JSON.stringify(mindUpPractice))
    this.formData = this.getFormData()
  }

  cancel () {
    this.formData = JSON.parse(JSON.stringify(this.getFormData()))
    this.$emit('cancel')
  }

  submit () {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          matchTagIds: this.formData.matchTags!.map(t => t!.id)
        }

        let uri = '/3l/mind-up-practices'
        if (this.mindUpPractice.id) {
          uri = `${uri}/${this.mindUpPractice.id}`
        }
        service.post(uri, data).then((res: AxiosResponse) => {
          this.onPracticeChanged(res.data)
          this.$emit('success', res.data)
        })
      } else {
        return false
      }
    })
  }
}
