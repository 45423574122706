
import { Options, Vue } from 'vue-class-component'
import ImagePicker from '@/components/ImagePicker.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { RadioBackgroundImage } from '@/models/radio'
import ImageUploader from '@/components/ImageUploader.vue'

@Options({
  components: { ImagePicker, ImageUploader }
})
export default class BackgroundImages extends Vue {
  dialogVisible = false
  backgroundImages: RadioBackgroundImage[] = []
  visualMode = 'LIGHT'
  publishedFilter?: boolean = undefined
  visualModeFilter?: string = undefined

  visualModeSelected (value? : string) : boolean {
    return this.visualModeFilter === value
  }

  publishedFilterSelected (value? : boolean) : boolean {
    return this.publishedFilter === value
  }

  updateImage (image: RadioBackgroundImage) : void {
    service.post(`/3l/radio/background-images/${image.id}`, image).then((res: AxiosResponse) => {
      Object.assign(image, res.data)
      if (!!this.visualModeFilter && this.visualModeFilter !== image.visualMode) {
        this.backgroundImages = this.backgroundImages.filter(i => i.id !== image.id)
      }
    })
  }

  filterImages (params: any) : void {
    this.visualModeFilter = params.visualMode
    this.publishedFilter = params.published
    service.get('/3l/radio/background-images', { params: params }).then((res: AxiosResponse) => {
      this.backgroundImages = res.data
    })
  }

  mounted () {
    this.loadData()
  }

  loadData () : void {
    this.filterImages({})
  }

  deleteImage (image: RadioBackgroundImage) : void {
    service.delete(`/3l/radio/background-images/${image.id}`).then((res: AxiosResponse) => {
      this.backgroundImages = this.backgroundImages.filter(i => i.id !== image.id)
    })
  }

  handleSelected (images: any[]) : void {
    this.dialogVisible = false
    service.post('/3l/radio/background-images/_batch', {
      images: images.map(i => {
        return {
          pexelsPhotoId: i.photoModalMediumId,
          smallImageSrc: i.photoModalImageGridItemSrc,
          src: i.photoModalImageSrc,
          visualMode: 'LIGHT'
        }
      })
    }).then((res: AxiosResponse) => {
      this.backgroundImages = [...this.backgroundImages, ...res.data]
    })
  }

  handleImageUploaded (file: any) : void {
    this.dialogVisible = false
    service.post('/3l/radio/background-images/_batch', {
      images: [{
        smallImageSrc: file.url,
        src: file.url,
        visualMode: 'LIGHT'
      }]
    }).then((res: AxiosResponse) => {
      this.backgroundImages = [...this.backgroundImages, ...res.data]
    })
  }

  unPublishImage (image: RadioBackgroundImage): void {
    service.post(`/3l/radio/background-images/${image.id}/unpublish`, {}).then((res: AxiosResponse) => {
      image.published = false
      if (this.publishedFilter !== undefined && this.publishedFilter !== image.published) {
        this.backgroundImages = this.backgroundImages.filter(i => i.id !== image.id)
      }
    })
  }

  publishImage (image: RadioBackgroundImage) : void {
    service.post(`/3l/radio/background-images/${image.id}/publish`, {}).then((res: AxiosResponse) => {
      image.published = true
      if (!!this.publishedFilter && this.publishedFilter !== image.published) {
        this.backgroundImages = this.backgroundImages.filter(i => i.id !== image.id)
      }
    })
  }
}
