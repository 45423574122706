
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '@/store/modules/auth'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  emits: ['success', 'cancel', 'before-upload']
})
export default class ImageUploader extends Vue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: AuthStore.token + '' }
  showUploading = false
  imageUrl = ''

  @Prop({
    default: false
  })
  multiple!: boolean

  handleUploaded (res: any, file: any, uploadFiles: any) {
    this.showUploading = false
    this.imageUrl = res.data.url
    if (this.multiple) {
      this.$emit('success', uploadFiles)
    } else {
      this.$emit('success', res.data)
    }
  }

  handleUploadError () {
    this.showUploading = false
  }

  beforeUpload (e: any) {
    this.imageUrl = ''
    this.$nextTick(() => {
      this.showUploading = true
    })
    this.$emit('before-upload')
  }
}
