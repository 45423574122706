
import { Options, Vue } from 'vue-class-component'
import { RadioMenu } from '@/models/radio'
import RadioMenuDialog from '@/components/RadioMenuDialog.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import RadioMenuDetailTable from '@/components/RadioMenuDetailTable.vue'

@Options({
  components: { RadioMenuDialog, RadioMenuDetailTable }
})
export default class AudioMenuConfig extends Vue {
  radioMenus: RadioMenu[] = []
  dialogVisible = false
  currentMenuId = 0
  currentMenuToEdit :RadioMenu = {
    name: '',
    sortNumber: 10,
    lightIcon: '',
    darkIcon: ''
  }

  selectedMenu (index: string) {
    this.currentMenuId = Number.parseInt(index)
  }

  mounted () {
    this.loadData()
  }

  editMenu (menu: RadioMenu) {
    this.currentMenuToEdit = menu
    this.dialogVisible = true
  }

  addMenu () {
    this.currentMenuToEdit = {
      name: '',
      sortNumber: 10,
      lightIcon: '',
      darkIcon: ''
    }
    this.dialogVisible = true
  }

  handleMenuUpdate (menu: RadioMenu) {
    const index = this.radioMenus.findIndex(p => p.id === menu.id)
    if (index > -1) {
      this.radioMenus.splice(index, 1, menu)
    } else {
      this.radioMenus.push(menu)
    }
  }

  deleteMenu (menu: RadioMenu) : void {
    service.delete(`/3l/radio/custom-menus/${menu.id}`).then((res: AxiosResponse) => {
      this.radioMenus = this.radioMenus.filter(m => m.id !== menu.id)
    })
  }

  loadData () {
    service.get('/3l/radio/custom-menus').then((res: AxiosResponse) => {
      this.radioMenus = res.data
    })
  }
}
