import { Module, VuexModule, getModule, MutationAction, Mutation } from 'vuex-module-decorators'
import store from '../index'
import service from '@/utils/service'
import { Tag } from '@/models/common'
import { CardImage } from '@/models/card'

@Module({
  name: 'images',
  dynamic: true,
  namespaced: true,
  store
})
export default class ImagesStoreModule extends VuexModule {
  images: any[] = []

  @Mutation
  setImages (images: any[]) {
    this.images = images
  }
}

export const ImagesStore = getModule(ImagesStoreModule)
