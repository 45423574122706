
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  emits: ['reference']
})
export default class ParagraphPanel extends Vue {
  @Prop() content!: string
}
