
import { Options, Vue } from 'vue-class-component'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType, Tag } from '@/models/common'
import { Demo, DemoSlice, Template, TemplateSlot } from '@/models/demo'
import SlotForm from '@/components/SlotForm.vue'
import { AxiosResponse } from 'axios'
import service from '@/utils/service'
import { LatestSlice } from '@/models/slice'
import DemoForm from '@/components/DemoForm.vue'

@Options({
  components: { SlotForm, DemoForm }
})
export default class DemoCreator extends Vue {
  slots: TemplateSlot[] = []
  template? : Template
  existDemo? : Demo
  existDemoSlices: DemoSlice[] = []
  slotSliceMap: Map<number, LatestSlice> = new Map<number, LatestSlice>()
  demoDialogVisible = false
  demo?: Demo
  demoFile = {
    url: '',
    name: '',
    fileSize: 0,
    duration: 0
  }

  get allFinished () {
    return this.slots.length - 1 === this.slotSliceMap.size
  }

  save () {
    if (this.existDemoId) {
      const data = {
        name: this.existDemo!.name!,
        templateId: this.existDemo!.templateId,
        demoUrl: this.demoFile.url,
        duration: this.demoFile.duration,
        fileSize: this.demoFile.fileSize,
        categoryId: this.existDemo!.categoryId,
        tags: this.existDemo!.tags,
        slices: this.slotSlices
      }
      service.post(`/3l/demos/${this.existDemoId}`, data).then((res: AxiosResponse) => {
        this.demoUpdated(res.data)
      })
    } else {
      this.demoDialogVisible = true
      this.demo = {
        name: '',
        templateId: this.templateId!,
        demoUrl: this.demoFile.url,
        duration: this.demoFile.duration,
        fileSize: this.demoFile.fileSize,
        tags: [],
        slices: this.slotSlices
      }
    }
  }

  createDemo () {
    this.demoDialogVisible = true
    this.demo = {
      name: '',
      templateId: this.templateId!,
      demoUrl: this.demoFile.url,
      duration: this.demoFile.duration,
      fileSize: this.demoFile.fileSize,
      categoryId: undefined,
      slices: this.slotSlices,
      tags: []
    }
  }

  get existDemoId () {
    return this.$route.query.demoId
  }

  get demoUrl () {
    return this.demoFile.url
  }

  loadData () {
    if (this.existDemoId) {
      service.get(`/3l/demos/${this.existDemoId}`).then((res: AxiosResponse<Demo>) => {
        this.existDemo = res.data
        this.demoFile = { name: '', url: this.existDemo!.demoUrl, duration: this.existDemo!.duration, fileSize: this.existDemo!.fileSize }
      })

      service.get(`/3l/demos/${this.existDemoId}/slices`).then((res: AxiosResponse) => {
        this.existDemoSlices = res.data
        this.existDemoSlices.forEach(slice => {
          this.slotSliceMap.set(slice.slotId, {
            id: slice.sliceId,
            version: slice.sliceVersion,
            words: slice.words,
            audio: slice.audio,
            duration: slice.duration,
            fileSize: slice.fileSize,
            name: '',
            tags: [],
            author: undefined
          })
        })
        console.log(this.slotSliceMap)
      })
    }

    service.get(`/3l/templates/${this.templateId}/slots`).then((res: AxiosResponse<Array<TemplateSlot>>) => {
      this.slots = res.data
      if (this.slots.length === 0) {
        this.$router.push({ name: 'TemplateSlots', params: this.$route.params })
        return
      }
      this.slots.push({
        id: -1,
        name: '',
        sortNumber: 999999999,
        tags: Array<Tag>()
      })
    })
  }

  demoUpdated (demo: Demo) {
    this.$router.push({ name: 'DemoDetail', params: { id: demo.id! } })
  }

  mergeDemo () {
    service.post('/files/merge', { audios: this.slots.slice(0, -1).map(slot => { return this.slotSliceMap.get(slot.id!)!.audio }) }).then((res: AxiosResponse) => {
      this.demoFile = res.data
    })
  }

  get slotSlices () {
    return this.slots.slice(0, -1).map(slot => {
      const slotId = slot.id!
      const latestSlice = this.slotSliceMap.get(slotId)
      return {
        slotId: slotId,
        sliceId: latestSlice!.id!,
        sliceVersion: latestSlice!.version,
        sortNumber: slot.sortNumber
      }
    })
  }

  getTitle (slot: TemplateSlot) {
    if (slot.id === -1) {
      return '结束'
    }

    return slot.name
  }

  beforeMount () {
    CategoryStore.loadData(CategoryType.DEMO)
    CategoryStore.loadData(CategoryType.SLICE)
    this.loadData()
  }

  goBack () {
    this.$router.back()
  }

  get templateId () : number {
    return Number.parseInt(this.$route.params.id as string)
  }
}
