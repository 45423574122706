
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Card, CommonCardTagCategory } from '@/models/card'
import { ElMessage } from 'element-plus'
import { CategoryStore } from '@/store/modules/category'
import { Category, CategoryType } from '@/models/common'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'

@Options({
  components: {}
})
export default class CardTagEditor extends Vue {
  @Prop()
  card!: Card

  @Prop()
  originalImageTags!: string[]

  categoryIdsToAdd: number[] = []
  addCardTagCategoryVisible = false

  commonCardTagCategories: CommonCardTagCategory[] = []

  isSelectedTag (tagId: number) {
    return this.card?.cardTags?.findIndex(ct => ct.tagId === tagId) > -1
  }

  saveCardText () {
    service.post(`/3l/cards/${this.card.id}`, { cardText: this.card.cardText }).then(() => {
      ElMessage({ message: '保存成功', type: 'success', showClose: true })
    })
  }

  clickTag (tagId: number) {
    if (this.isSelectedTag(tagId)) {
      service.delete(`/3l/cards/${this.card.id}/tags/${tagId}`, {
        data: {
          showLoading: false
        }
      }).then((res: AxiosResponse<CommonCardTagCategory[]>) => {
        this.card.cardTags = this.card.cardTags.filter(ct => ct.tagId !== tagId)
        this.$forceUpdate()
      })
    } else {
      service.post(`/3l/cards/${this.card.id}/tags`, { tagId, showLoading: false }).then(() => {
        this.card.cardTags.push({ cardId: this.card.id, tagId })
        this.$forceUpdate()
      })
    }
  }

  deleteCommonCardTagCategory (categoryId: number) {
    service.delete(`/3l/cards/common-tag-categories/${categoryId}`).then(() => {
      this.commonCardTagCategories.splice(this.commonCardTagCategories.findIndex(c => c.tagCategoryId === categoryId), 1)
    })
  }

  get tagCategories () {
    return CategoryStore.tagCategories
  }

  @Watch('card')
  onCardChanged (card: Card) {
    service.get(`/3l/cards/${this.card.id}/tags`).then((res: AxiosResponse<any[]>) => {
      this.card.cardTags = res.data
    })
  }

  mounted () {
    CategoryStore.loadData(CategoryType.TAG)
    service.get('/3l/cards/common-tag-categories').then((res: AxiosResponse<CommonCardTagCategory[]>) => {
      this.commonCardTagCategories = res.data
    })

    service.get(`/3l/cards/${this.card.id}/tags`).then((res: AxiosResponse<any[]>) => {
      this.card.cardTags = res.data
    })
  }

  addCardTagCategory () {
    if (this.categoryIdsToAdd.length === 0) {
      ElMessage({ message: '请输入分类', type: 'error', showClose: true })
      return
    }

    const categoryIdToAdd = this.categoryIdsToAdd[this.categoryIdsToAdd.length - 1]
    if (this.commonCardTagCategories.findIndex(c => c.tagCategoryId === categoryIdToAdd) > -1) {
      ElMessage({ message: '当前分类已存在', type: 'error', showClose: true })
    } else {
      service.post('/3l/cards/common-tag-categories', { categoryId: categoryIdToAdd }).then((res: AxiosResponse<CommonCardTagCategory>) => {
        this.commonCardTagCategories.push(res.data)
        this.categoryIdsToAdd = []
        this.addCardTagCategoryVisible = false
      })
    }
  }
}
