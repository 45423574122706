
import { Options, Vue } from 'vue-class-component'
import { Tag } from '@/models/common'
import { TagStore } from '@/store/modules/tags'
import { ElMessage } from 'element-plus'
import { Model, Prop, Watch } from 'vue-property-decorator'

@Options({
  components: {},
  emits: ['success', 'cancel', 'changed']
})
export default class TagInput extends Vue {
  @Prop({ default: false })
  allowAddNewTag!: boolean

  @Prop({ default: undefined })
  givenOptions?: Tag[]

  @Prop({ default: false })
  alwaysShowInput!: boolean

  @Prop({ default: false })
  singleMode!: boolean

  @Prop({ default: false })
  skipLoadData!: boolean

  @Model('modelValue', {
    type: Array,
    default: []
  })
  dynamicTags!: Tag[]

  inputVisible = false
  inputValue = ''
  loaded = false

  get tags (): Tag[] {
    return (this.givenOptions || TagStore.tags).filter(tag => !this.dynamicTags.find(item => item.name === tag.name))
  }

  loadData () {
    if (!this.loaded && !this.skipLoadData) {
      TagStore.loadData()
      this.loaded = true
    }
  }

  handleClose (tag: Tag) {
    this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
  }

  showInput () {
    this.loadData()
    this.inputVisible = true
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).focus()
    })
  }

  querySearch (queryString: string, cb: (results: any) => void) {
    const results = queryString
      ? this.tags.filter(this.createFilter(queryString))
      : this.tags
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter (queryString: string): (results: any) => void {
    return (tag) => {
      return tag.name.toLowerCase().indexOf(queryString.toLowerCase()) > -1
    }
  }

  handleSelect (tag: Tag): void {
    if (this.singleMode) {
      this.dynamicTags = [tag]
    } else {
      this.dynamicTags.push(tag)
    }
    this.inputVisible = false
    this.inputValue = ''
  }

  @Watch('dynamicTags', { deep: true, immediate: true })
  onTagChanged () : void {
    this.$emit('changed')
  }

  handleInputConfirm () {
    if (!this.allowAddNewTag) {
      return
    }

    if (this.inputValue) {
      const tag = this.tags.find(tag => tag.name === this.inputValue) || { name: this.inputValue }
      if (!tag.id) {
        ElMessage({ message: '标签不存在', type: 'warning', showClose: true })
        this.reset()
        return
      }

      if (this.dynamicTags.find(item => item.name === tag.name)) {
        ElMessage({ message: '请勿输入重复标签', type: 'warning', showClose: true })
        this.reset()
        return
      } else {
        if (this.singleMode) {
          this.dynamicTags = [tag]
        } else {
          this.dynamicTags.push(tag)
        }
      }
    }

    this.reset()
  }

  reset () : void {
    this.inputVisible = false
    this.inputValue = ''
  }
}
