
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {}
})
export default class UnAuthorized extends Vue {
  logout () {
    AuthStore.removeToken()
    this.$router.push({ name: 'Login' })
  }
}
