
import { Options, Vue } from 'vue-class-component'
import { Model, Prop, Watch } from 'vue-property-decorator'
import { TemplateSlot } from '@/models/demo'
import { AxiosResponse } from 'axios'
import service from '@/utils/service'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import { LatestSlice } from '@/models/slice'
import SearchInput from '@/components/SearchInput.vue'

@Options({
  components: { SearchInput },
  emits: ['cancel', 'slice-selected']
})
export default class SliceSelector extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  dialogVisible!: boolean

  @Prop()
  slotData!: TemplateSlot

  tableData: any[] = []
  searchName = ''
  filteredData: any[] = []

  handleSearch (key: string) {
    this.filteredData = this.tableData.filter(s => s.name.indexOf(key) > -1)
  }

  @Watch('dialogVisible')
  onDialogVisibleChange (dialogVisible: boolean) {
    this.resetSearch()
  }

  resetSearch () {
    this.searchName = ''
    this.filteredData = this.tableData
  }

  beforeMount () {
    const tags = this.slotData.tags
    const categoryIds = this.slotData.matchCategoryId ? [this.slotData.matchCategoryId!, ...CategoryStore.getAllChildrenById(this.slotData.matchCategoryId!, CategoryType.SLICE)] : []
    service.get('/3l/slices/latest', {
      params: {
        categoryIds: categoryIds.length > 0 ? categoryIds.join(',') : undefined,
        tagIds: tags.length > 0 ? tags.map(item => item.id).join(',') : undefined
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data.content
      this.searchName = ''
      this.filteredData = this.tableData
    })
  }

  handleRowClick (data: LatestSlice) {
    this.dialogVisible = false
    this.$emit('slice-selected', { slotId: this.slotData.id, slice: data })
  }

  cancel () {
    this.dialogVisible = false
    this.$emit('cancel')
  }
}
