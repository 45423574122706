
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '@/store/modules/auth'
import { User } from '@/models/user'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
])
@Options({
  components: {}
})
export default class Layout extends Vue {
  links = [
    {
      name: 'Tags',
      title: '标签库'
    },
    {
      name: 'Slices',
      title: '段落库'
    },
    {
      name: 'Templates',
      title: '模板库'
    },
    {
      name: 'Demos',
      title: '成品库'
    }, {
      name: 'Books',
      title: '书籍库'
    }, {
      name: 'Words',
      title: '文案库'
    }, {
      name: 'Cards',
      title: '卡牌库'
    }, {
      name: 'Radio',
      title: '电台配置'
    }, {
      name: 'Users',
      title: '用户管理'
    }
  ]

  defaultAvatar = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'

  beforeRouteEnter (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (to.name !== 'Layout') {
      next()
    } else {
      const roles = AuthStore.user?.roles || ''
      if (roles.indexOf('3L__miaoYinAdmin') > -1) {
        next({ name: 'Slices' })
      } else if (roles.indexOf('3L__writerAdmin') > -1) {
        next({ name: 'Words' })
      } else if (roles.indexOf('3L__superAdmin') > -1) {
        next({ name: 'Users' })
      } else if (roles.indexOf('3L__cardAdmin') > -1) {
        next({ name: 'Cards' })
      } else {
        next({ name: 'UnAuthorized' })
      }
    }
  }

  get activeIndex () {
    return this.allowedMenus.findIndex(link => link.name === this.$route.name || this.$route.path.startsWith(this.getRoute(link).path)) + ''
  }

  private getRoute (link: any) : any {
    return this.$router.getRoutes().find(r => r.name === link.name)!
  }

  get allowedMenus () {
    return this.links.filter(link => this.getRoute(link).meta.roles.filter((role: string) => this.user?.roles?.indexOf(role) !== -1).length > 0)
  }

  get user () : User | undefined {
    return AuthStore.user || undefined
  }

  goto (name: string) {
    this.$router.push({ name })
  }

  logout () {
    AuthStore.removeToken()
    this.$router.push({ name: 'Login' })
  }
}
