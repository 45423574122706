
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { EditMode, Category, Tag } from '@/models/common'
import CategoryTree from '@/components/CategoryTree.vue'
import { Template } from '@/models/demo'
import TemplateForm from '@/components/TemplateForm.vue'

@Options({
  components: {
    TemplateForm,
    CategoryTree
  }
})
export default class TemplateList extends Vue {
  filterTags: Tag[] = []
  currentSelectedCategories: Category[] = []
  currentCategoryId = 0
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<Template>()
  }

  templateDialogVisible = false
  currentTemplate? : Template
  editMode = EditMode.ADD

  beforeMount (): void {
    this.reloadPage(1)
  }

  templateUpdated (data: any) {
    if (this.editMode === EditMode.ADD) {
      this.$router.push({ name: 'TemplateDetail', params: { id: data.id } })
      this.tableData.totalCount++
    } else {
      const oldData = this.tableData.content.find(t => t.id === data.id)
      Object.assign(oldData, data)
      this.templateDialogVisible = false
    }
  }

  reloadPage (pageNumber: number) {
    service.get('/3l/templates', {
      params: {
        categoryIds: this.categoryIds,
        tagIds: this.filterTags.length > 0 ? this.filterTags.map(item => item.id).join(',') : undefined,
        pageNum: pageNumber,
        pageSize: 10
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  addTemplate () {
    this.editMode = EditMode.ADD
    this.currentTemplate = {
      name: '',
      categoryId: this.currentCategoryIdForEditor
    }
    this.templateDialogVisible = true
  }

  editTemplate (template: Template) {
    this.editMode = EditMode.MODIFY
    this.currentTemplate = template
    this.templateDialogVisible = true
  }

  get categoryIds () {
    return this.currentSelectedCategories.length > 0 ? this.currentSelectedCategories.map(i => { return i.id }).join(',') : undefined
  }

  get currentCategoryIdForEditor () : number | undefined {
    if (this.editMode === EditMode.ADD && this.currentSelectedCategories.length > 0) {
      return this.currentSelectedCategories[0].id!
    } else if (this.editMode === EditMode.MODIFY && this.currentTemplate) {
      return this.currentTemplate!.categoryId!
    } else {
      return undefined
    }
  }

  filterCategory (currentSelectedCategories: Category[]) {
    this.currentSelectedCategories = currentSelectedCategories
    this.reloadPage(1)
  }

  handleRowClick (template: Template) : void {
    this.$router.push({ name: 'DemoCreator', params: { id: template.id! } })
  }

  deleteTemplate (template: Template) {
    service.delete(`/3l/templates/${template.id}`, {}).then(() => {
      this.tableData.totalCount--
      this.tableData.content = this.tableData.content.filter(item => { return item.id !== template.id })
    })
  }
}
