
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryType, Category, EditMode } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Words } from '@/models/words'
import TagInput from '@/components/TagInput.vue'

@Options({
  components: { TagInput },
  emits: ['success', 'cancel']
})
export default class WordsForm extends Vue {
  @Prop()
  categoryId!: number

  editMode = EditMode.ADD
  editing = false

  @Prop()
  words!: Words

  currentWords = this.words
  categoryPath: Category[] = []
  templateCategoryStore = CategoryStore

  formData = {
    ...this.currentWords,
    categoryIds: this.categoryIdPath
  }

  @Watch('categoryId', { immediate: true })
  onCategoryIdsChanged () {
    this.formData.categoryIds = this.categoryIdPath
  }

  get categoryIdPath () {
    if (this.categoryId) {
      return CategoryStore.findParentAndSelf(this.categoryId!, CategoryType.WORDS).map(item => { return item.id! })
    }

    return []
  }

  get isEditing () {
    return this.editMode === EditMode.ADD || (this.editMode === EditMode.MODIFY && this.editing)
  }

  @Watch('words', { immediate: true })
  onWordsChanged (words: Words) {
    this.editMode = words.id ? EditMode.MODIFY : EditMode.ADD
    this.currentWords = words
    if (!this.currentWords.categoryId) {
      this.categoryPath = [{
        parentId: 0,
        code: '未分类',
        type: CategoryType.WORDS,
        name: '未分类'
      }]
      this.formData = JSON.parse(JSON.stringify({ ...this.currentWords, categoryIds: [] }))
    } else {
      this.categoryPath = CategoryStore.findParentAndSelf(this.currentWords.categoryId!, CategoryType.WORDS)
      this.formData = JSON.parse(JSON.stringify({ ...this.currentWords, categoryIds: this.categoryPath.map(item => item.id!) }))
    }
  }

  cancel () {
    this.formData = JSON.parse(JSON.stringify({ ...this.currentWords, categoryIds: this.categoryPath.map(item => item.id!) }))
    this.editing = false
    this.$emit('cancel')
  }

  onSubmit () {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          categoryId: this.formData.categoryIds.slice(-1)[0]
        }

        let uri = '/3l/words'
        if (this.words.id) {
          uri = `${uri}/${this.words.id}`
        }
        service.post(uri, data).then((res: AxiosResponse) => {
          this.editing = false
          this.onWordsChanged(res.data)
          this.$emit('success', res.data)
        })
      } else {
        return false
      }
    })
  }
}
