
import { Options, Vue } from 'vue-class-component'
import TimeBoxesDialog from '@/components/TimeBoxesDialog.vue'
import RadioPlaylistTable from '@/components/RadioPlaylistTable.vue'
import { Watch } from 'vue-property-decorator'
import { RadioStore } from '@/store/modules/radio'

@Options({
  components: { TimeBoxesDialog, RadioPlaylistTable }
})
export default class FMCalendar extends Vue {
  dialogVisible = false
  currentDay = new Date()
  get configuredDays () : string[] {
    return RadioStore.configuredDays
  }

  @Watch('currentDay')
  onCurrentDayChange (date: Date, oldDate: Date) {
    if (oldDate.toISOString() !== date.toISOString()) {
      if (oldDate.getMonth() !== date.getMonth()) {
        RadioStore.loadConfiguredDays(date)
      }
    }
  }

  mounted () {
    const today = new Date()
    RadioStore.loadTimeBoxes()
    RadioStore.loadConfiguredDays(today)
  }
}
