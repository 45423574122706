
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Category, Tag } from '@/models/common'
import TagFilter from '@/components/TagFilter.vue'
import CategoryTree from '@/components/CategoryTree.vue'
import { CategoryStore } from '@/store/modules/category'
import SliceForm from '@/components/SliceForm.vue'
import PublishDialog from '@/components/PublishDialog.vue'
import { Slice } from '@/models/slice'

@Options({
  components: {
    SliceForm,
    TagFilter,
    PublishDialog,
    CategoryTree
  }
})
export default class SliceList extends Vue {
  filterTags: Tag[] = []
  currentSelectedCategories: Category[] = []
  currentCategoryId = 0
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<Slice>()
  }

  sliceDialogVisible = false
  publishDialogVisible = false
  currentSelectedSlice! : Slice

  publish (data: any) {
    service.post(`/3l/slices/${this.currentSelectedSlice.id!}/versions`, data).then((res: AxiosResponse) => {
      this.publishDialogVisible = false
      const index = this.tableData.content.findIndex(d => d.id === this.currentSelectedSlice.id!)
      this.tableData.content.splice(index, 1, res.data)
    })
  }

  beforeMount (): void {
    this.reloadPage(1)
  }

  sliceCreated (data: any) {
    this.sliceDialogVisible = false
    this.currentCategoryId = data.categoryId
    this.currentSelectedCategories = [data.category, ...CategoryStore.getAllChildren(data.category)]
    this.reloadPage(1)
  }

  reloadPage (pageNumber: number) {
    service.get('/3l/slices', {
      params: {
        categoryIds: this.categoryIds,
        tagIds: this.filterTags.length > 0 ? this.filterTags.map(item => item.id).join(',') : undefined,
        pageNum: pageNumber,
        pageSize: 10
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  openDialogForPublish (slice: Slice) {
    this.currentSelectedSlice = slice
    this.publishDialogVisible = true
  }

  get categoryIds () {
    return this.currentSelectedCategories.length > 0 ? this.currentSelectedCategories.map(i => { return i.id }).join(',') : undefined
  }

  get categoryForEditor () : number | undefined {
    if (this.currentSelectedCategories.length > 0) {
      return this.currentSelectedCategories[0].id!
    } else {
      return undefined
    }
  }

  filterByTags () {
    this.reloadPage(1)
  }

  filterCategory (currentSelectedCategories: Category[]) {
    this.currentSelectedCategories = currentSelectedCategories
    this.reloadPage(1)
  }

  handleRowClick (slice: Slice) : void {
    this.$router.push({ name: 'SliceDetail', params: { id: slice.id! } })
  }

  deleteSlice (slice: Slice) {
    service.delete(`/3l/slices/${slice.id}`, {}).then(() => {
      this.tableData.totalCount--
      this.tableData.content = this.tableData.content.filter(item => { return item.id !== slice.id })
    })
  }
}
