
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryType, Category, EditMode } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'

@Options({
  components: {},
  emits: ['success', 'cancel']
})
export default class CategoryForm extends Vue {
  @Prop() categoryType! : CategoryType
  @Prop() category?: Category
  @Prop() parentCategory!: Category
  @Prop() editMode!: EditMode

  categoryForm: any = {}

  beforeMount () {
    this.updateFormData()
  }

  @Watch('category')
  updateFormData () : void {
    this.categoryForm = {
      parentId: this.parentCategory.id,
      code: this.category?.code.slice(this.parentCategory.code.length),
      name: this.category?.name
    }
  }

  onSave () : void {
    (this.$refs.formDom as any).validate((valid: boolean) => {
      if (valid) {
        if (this.editMode === EditMode.ADD) {
          CategoryStore.create({ type: this.categoryType, ...this.categoryForm, code: this.parentCategory.code + this.categoryForm.code! }).then((data: Category) => {
            this.$emit('success', data)
          })
        } else {
          CategoryStore.update({ id: this.category!.id!, type: this.categoryType, ...this.categoryForm, code: this.parentCategory.code + this.categoryForm.code }).then((data: Category) => {
            this.$emit('success', data)
          })
        }
      } else {
        return false
      }
    })
  }
}
