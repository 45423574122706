
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { TagStore } from '@/store/modules/tags'
import CardSetAssemblyForm from '@/components/CardSetAssemblyForm.vue'
import { CardSetAssembly } from '@/models/card'
import AlternativeCardList from '@/components/AlternativeCardList.vue'

@Options({
  components: { AlternativeCardList, CardSetAssemblyForm },
  emits: ['assembly-created']
})
export default class CardSetPreview extends Vue {
  @Prop()
  cardSetParts: any[] = []

  alternativeCards: any[] = []

  @Prop()
  cardSetId?: number

  @Prop({ default: false })
  saveable!: boolean

  cardSetAssemblyDialogVisible = false
  changeCardDialogVisible = false
  cardToChange : any
  cardSetPartToChange: any

  currentSelectedCardSet: CardSetAssembly = {
    name: '',
    description: '',
    tags: [],
    parts: []
  }

  @Watch('cardSetParts')
  onCardSetPartsChange () {
    this.loadPreview()
  }

  mounted () {
    this.loadPreview()
  }

  cardSetAssemblyCreated (assembly: any) {
    this.cardSetAssemblyDialogVisible = false
    this.$emit('assembly-created', assembly)
  }

  changeCard (cardSetPart: any, card: any) {
    this.cardSetPartToChange = cardSetPart
    this.cardToChange = card
    this.changeCardDialogVisible = true
  }

  getTag (tagId: any) {
    return TagStore.getTagById(Number.parseInt(tagId))
  }

  loadPreview () {
    service.post('/3l/card-sets/preview', this.cardSetParts.map(cardSetPart => {
      return {
        tagIds: cardSetPart.tagIds.join(','),
        cardCount: cardSetPart.cardCount
      }
    })).then((res: AxiosResponse<any[]>) => {
      this.cardSetParts.forEach(cardSetPart => {
        cardSetPart.previewResult = res.data.find(r => r.tagIds.join(',') === cardSetPart.tagIds.join(','))
      })
    })
  }

  saveAssembly () {
    this.cardSetAssemblyDialogVisible = true
    this.currentSelectedCardSet = {
      name: '',
      description: '',
      cardSetId: this.cardSetId,
      tags: [],
      parts: this.cardSetParts.map(p => {
        return {
          cardSetPartId: p.id,
          cardSetId: this.cardSetId,
          cardCount: p.cardCount,
          textVisible: p.textVisible,
          tagIds: p.tagIds,
          cardIds: p.previewResult.cards.map((c: any) => c.id)
        }
      })
    }
  }
}
