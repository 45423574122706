
import { Options, Vue } from 'vue-class-component'
import { CategoryType, Category, EditMode } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import { Prop, Watch } from 'vue-property-decorator'
import { ElTree } from 'element-plus'
import CategoryForm from '@/components/CategoryForm.vue'

@Options({
  components: { CategoryForm },
  emits: ['node-click']
})
export default class CategoryTree extends Vue {
  @Prop() categoryType!: CategoryType
  @Prop() currentCategoryId?: number

  filterText = ''
  currentKey = 0
  categoryEditMode = EditMode.ADD
  categoryDialogVisible = false
  currentNode?: Category = undefined
  currentParentNode?: Category
  defaultProps = {
    children: 'children',
    label: 'name'
  }

  mounted (): void {
    CategoryStore.loadData(this.categoryType)
  }

  @Watch('currentCategoryId', { immediate: true })
  onCurrentCategoryIdChanged (value: number): void {
    this.currentKey = value
  }

  @Watch('filterText')
  onFilterTextChanged (val: string, oldVal: string) {
    (this.$refs.tree as (typeof ElTree)).filter(val)
  }

  get treeData (): Category[] {
    return [{
      parentId: 0,
      id: 0,
      code: '',
      name: '所有分类',
      type: this.categoryType,
      children: CategoryStore.categoriesMap.get(this.categoryType)!
    }]
  }

  filterNode (value: string, data: Category) {
    if (!value) {
      return true
    }
    return data.name.indexOf(value) !== -1 || data.code.indexOf(value) !== -1
  }

  addCategory (parent: Category) {
    this.categoryDialogVisible = true
    this.categoryEditMode = EditMode.ADD
    this.currentParentNode = parent
    this.currentNode = {
      parentId: parent.id!,
      type: parent.type,
      code: '',
      name: ''
    }
  }

  deleteCategory (data: Category) {
    CategoryStore.delete(data).then(() => {
      const parent = CategoryStore.findById(data.parentId, data.type)
      this.$emit('node-click', parent ? [parent] : [])
    })
  }

  editCategory (data: Category, parent: Category) {
    this.categoryDialogVisible = true
    this.categoryEditMode = EditMode.MODIFY
    this.currentNode = data
    this.currentParentNode = parent
  }

  categoryUpdated (data: Category): void {
    this.categoryDialogVisible = false
    if (this.categoryEditMode === EditMode.ADD) {
      this.currentKey = data.id!
      this.$emit('node-click', [data])
    }
  }

  handleNodeClick (data: Category) {
    this.currentKey = data.id!
    let currentSelectedCategories: Category[] = []
    if (data.id !== 0) {
      currentSelectedCategories = [data, ...CategoryStore.getAllChildren(data)]
    }
    this.$emit('node-click', currentSelectedCategories)
  }
}
