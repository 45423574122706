
import { Options, Vue } from 'vue-class-component'
import { Model, Prop, Watch } from 'vue-property-decorator'
import { AuthStore } from '@/store/modules/auth'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { RadioAudio } from '@/models/radio'
import { Demo } from '@/models/demo'
import DemoList from '@/components/DemoList.vue'
import ImagePicker from '@/components/ImagePicker.vue'
import ImageUploader from '@/components/ImageUploader.vue'

@Options({
  components: { DemoList, ImagePicker, ImageUploader },
  emits: ['success', 'cancel']
})
export default class RadioMenuAudioDialog extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  dialogVisible!: boolean

  demoSelectedDialogVisible = false
  imageDialogVisible = false
  currentOperateVisualMode = ''

  showLightBackgroundUploading = false
  showDarkBackgroundUploading = false

  selectForLightBackground () {
    this.currentOperateVisualMode = 'LIGHT'
    this.imageDialogVisible = true
  }

  selectForDarkBackground () {
    this.currentOperateVisualMode = 'DARK'
    this.imageDialogVisible = true
  }

  handleLightBackgroundUploaded (file: any) {
    this.formData.lightBackground = file.url
  }

  handleDarkBackgroundUploaded (file: any) {
    this.formData.darkBackground = file.url
  }

  handleImageSelected (image: any) {
    if (this.currentOperateVisualMode === 'LIGHT') {
      this.formData.lightBackground = image.photoModalImageSrc
    } else {
      this.formData.darkBackground = image.photoModalImageSrc
    }
    this.imageDialogVisible = false
  }

  @Prop()
  menuId!: number

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: AuthStore.token + '' }

  @Prop()
  audio!: RadioAudio

  formData: RadioAudio = {
    name: '',
    menuId: this.menuId,
    audio: '',
    lightBackground: '',
    darkBackground: '',
    demoId: undefined,
    demoVersion: undefined,
    duration: undefined,
    fileSize: undefined
  }

  demoSelected (demo: Demo) {
    this.formData.audio = demo.demoUrl
    this.formData.demoId = demo.id!
    this.formData.demoVersion = demo.latestVersion
    this.formData.duration = demo.duration
    this.formData.fileSize = demo.fileSize
    this.demoSelectedDialogVisible = false
  }

  @Watch('audio')
  onMenuChange (audio: RadioAudio) {
    this.formData = JSON.parse(JSON.stringify(this.audio))
  }

  submit () {
    (this.$refs.formDom as any).validate((valid: boolean) => {
      if (valid) {
        let uri = `/3l/radio/custom-menus/${this.menuId}/audios`
        if (this.audio.id) {
          uri += `/${this.audio.id}`
        }
        service.post(uri, this.formData).then((res: AxiosResponse) => {
          this.$emit('success', res.data)
          this.dialogVisible = false
        })
      } else {
        return false
      }
    })
  }

  cancel () {
    this.dialogVisible = false
    this.$emit('cancel')
    this.formData = JSON.parse(JSON.stringify(this.audio))
  }
}
