import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router'

import { AuthStore } from '@/store/modules/auth'
import { ElMessage } from 'element-plus'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    meta: {
      title: '主页'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Layout.vue'),
    children: [
      {
        path: 'slices',
        name: 'Slices',
        meta: {
          roles: ['3L__miaoYinAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/slices/Slices.vue'),
        redirect: { name: 'SliceList' },
        children: [
          {
            path: '',
            name: 'SliceList',
            component: () => import(/* webpackChunkName: "about" */ '../views/slices/SliceList.vue')
          },
          {
            path: ':id',
            name: 'SliceDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/slices/SliceDetail.vue'),
            redirect: { name: 'SliceBasic' },
            children: [
              {
                path: '',
                name: 'SliceBasic',
                component: () => import(/* webpackChunkName: "about" */ '../views/slices/SliceBasic.vue')
              },
              {
                path: 'versions',
                name: 'SliceVersionList',
                component: () => import(/* webpackChunkName: "about" */ '../views/slices/SliceVersionList.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'tags',
        name: 'Tags',
        meta: {
          roles: ['3L__miaoYinAdmin', '3L__writerAdmin', '3L__cardAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/tags/TagList.vue')
      },
      {
        path: 'demos',
        name: 'Demos',
        meta: {
          roles: ['3L__miaoYinAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/demos/Demos.vue'),
        redirect: { name: 'DemoList' },
        children: [
          {
            path: '',
            name: 'DemoList',
            component: () => import(/* webpackChunkName: "about" */ '../components/DemoList.vue')
          },
          {
            path: ':id',
            name: 'DemoDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/demos/DemoDetail.vue'),
            redirect: { name: 'DemoStructure' },
            children: [
              {
                path: 'basic',
                name: 'DemoBasic',
                component: () => import(/* webpackChunkName: "about" */ '../views/demos/DemoBasic.vue')
              },
              {
                path: '',
                name: 'DemoStructure',
                component: () => import(/* webpackChunkName: "about" */ '../views/demos/DemoStructure.vue')
              },
              {
                path: 'versions',
                name: 'DemoVersionList',
                component: () => import(/* webpackChunkName: "about" */ '../views/demos/DemoVersionList.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'books',
        name: 'Books',
        meta: {
          roles: ['3L__writerAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/books/Books.vue'),
        redirect: { name: 'BookList' },
        children: [
          {
            path: '',
            name: 'BookList',
            component: () => import(/* webpackChunkName: "about" */ '../views/books/BookList.vue')
          },
          {
            path: ':id',
            name: 'BookDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/books/BookDetail.vue')
          }
        ]
      },
      {
        path: 'words',
        name: 'Words',
        meta: {
          roles: ['3L__writerAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/words/Words.vue'),
        redirect: { name: 'WordsList' },
        children: [
          {
            path: '',
            name: 'WordsList',
            component: () => import(/* webpackChunkName: "about" */ '../views/words/WordsList.vue')
          },
          {
            path: ':id',
            name: 'WordsDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/words/WordsDetail.vue'),
            redirect: { name: 'WordsDetailEditor' },
            children: [
              {
                path: 'basic',
                name: 'WordsBasic',
                component: () => import(/* webpackChunkName: "about" */ '../views/words/WordsBasic.vue')
              },
              {
                path: '',
                name: 'WordsDetailEditor',
                component: () => import(/* webpackChunkName: "about" */ '../views/words/WordsEditor.vue')
              }
            ]
          },
          {
            path: 'editor',
            name: 'WordsEditor',
            component: () => import(/* webpackChunkName: "about" */ '../views/words/WordsEditor.vue')
          }
        ]
      },
      {
        path: 'templates',
        name: 'Templates',
        meta: {
          roles: ['3L__miaoYinAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/templates/Templates.vue'),
        redirect: { name: 'TemplateList' },
        children: [
          {
            path: '',
            name: 'TemplateList',
            component: () => import(/* webpackChunkName: "about" */ '../views/templates/TemplateList.vue')
          },
          {
            path: ':id',
            name: 'TemplateDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/templates/TemplateDetail.vue'),
            redirect: { name: 'DemoCreator' },
            children: [
              {
                path: 'basic',
                name: 'TemplateBasic',
                component: () => import(/* webpackChunkName: "about" */ '../views/templates/TemplateBasic.vue')
              },
              {
                path: 'slots',
                name: 'TemplateSlots',
                component: () => import(/* webpackChunkName: "about" */ '../views/templates/TemplateSlots.vue')
              },
              {
                path: '',
                name: 'DemoCreator',
                component: () => import(/* webpackChunkName: "about" */ '../views/templates/DemoCreator.vue')
              }]
          }
        ]
      }, {
        path: 'radio',
        name: 'Radio',
        meta: {
          roles: ['3L__miaoYinAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/radio/Radio.vue'),
        redirect: { name: 'FMCalendar' },
        children: [
          {
            path: '',
            name: 'FMCalendar',
            component: () => import(/* webpackChunkName: "about" */ '../views/radio/FMCalendar.vue')
          },
          {
            path: 'mindup',
            name: 'MindUpList',
            component: () => import(/* webpackChunkName: "about" */ '../views/radio/MindUpList.vue')
          },
          {
            path: 'audio-menus',
            name: 'AudioMenuConfig',
            component: () => import(/* webpackChunkName: "about" */ '../views/radio/AudioMenuConfig.vue')
          },
          {
            path: 'images',
            name: 'BackgroundImages',
            component: () => import(/* webpackChunkName: "about" */ '../views/radio/BackgroundImages.vue')
          }]
      }, {
        path: 'cards',
        name: 'Cards',
        meta: {
          roles: ['3L__cardAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/cards/Cards.vue'),
        redirect: { name: 'CardSetList' },
        children: [
          {
            path: 'keywords',
            name: 'CardKeywordList',
            component: () => import(/* webpackChunkName: "about" */ '../views/cards/CardKeywordList.vue')
          },
          {
            path: 'sets',
            name: 'CardSetList',
            component: () => import(/* webpackChunkName: "about" */ '../views/cards/CardSetList.vue')
          },
          {
            path: '',
            name: 'AllCardList',
            component: () => import(/* webpackChunkName: "about" */ '../views/cards/AllCardList.vue')
          }
        ]
      }, {
        path: 'cards/keywords/:id',
        name: 'CardKeyword',
        meta: {
          roles: ['3L__cardAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/cards/CardKeyword.vue'),
        redirect: { name: 'PreSelect' },
        children: [
          {
            path: 'pre-select',
            name: 'PreSelect',
            component: () => import(/* webpackChunkName: "about" */ '../views/cards/PreSelect.vue')
          },
          {
            path: 'crop',
            name: 'Crop',
            component: () => import(/* webpackChunkName: "about" */ '../views/cards/Crop.vue')
          },
          {
            path: 'cards',
            name: 'KeywordCardList',
            component: () => import(/* webpackChunkName: "about" */ '../views/cards/KeywordCardList.vue')
          }
        ]
      }, {
        path: 'users',
        name: 'Users',
        meta: {
          roles: ['3L__superAdmin']
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/users/UserList.vue')
      }, {
        path: '/unauthorized',
        name: 'UnAuthorized',
        meta: {
          title: '未授权'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/UnAuthorized.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }, {
    path: '/register',
    name: 'Register',
    meta: {
      title: '注册'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const whiteList = ['/login']

const authorizeToken = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  document.title = to.meta.title + ''

  const tokenParam = to.query.token
  if (tokenParam) {
    AuthStore.setToken(tokenParam + '')
    next()
  } else {
    const token = AuthStore.token
    if (token && AuthStore.isTokenValid) {
      if (to.path === '/login') {
        next({ path: '/' })
      } else {
        next()
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next(`/login?redirect=${to.path}`)
      }
    }
  }
}

const ssoCheck = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.query.state === 'login') {
    const code = to.query.code
    next({ path: 'login', query: { code } })
  } else {
    next()
  }
}

const checkAuth = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  function hasNotAccount () {
    return AuthStore.user?.identities?.indexOf('3L|Account') === -1 && to.name !== 'Register'
  }

  if (hasNotAccount()) {
    ElMessage({ message: '请使用当前微信绑定手机号注册后台管理员账号', type: 'warning', showClose: true })
    next({ name: 'Register' })
  } else {
    const roles = (to.meta as any)?.roles
    if ((!roles) || roles?.filter((role: string) => AuthStore.user?.roles?.indexOf(role) !== -1).length > 0) {
      next()
    } else {
      next({ name: 'UnAuthorized' })
    }
  }
}

router.beforeEach(ssoCheck)
router.beforeEach(authorizeToken)
router.beforeEach(checkAuth)

export default router
