
import { Options, Vue } from 'vue-class-component'
import { Model, Watch } from 'vue-property-decorator'
import { CategoryType, Tag } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import TagInput from '@/components/TagInput.vue'
import { MindUpOptions } from '@/models/mindup'
import { ElMessage } from 'element-plus'
import { RadioStore } from '@/store/modules/radio'
import { TagStore } from '@/store/modules/tags'

@Options({
  components: { TagInput },
  emits: ['success', 'cancel']
})
export default class MindUpMatchRuleDialog extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  dialogVisible!: boolean

  formData = {
    categoryIds: new Array<number>(),
    tags: new Array<Tag>(),
    options: MindUpOptions.map(o => {
      return {
        ...o,
        defaultTags: new Array<Tag>(),
        nonDefaultTags: new Array<Tag>()
      }
    })
  }

  @Watch('dialogVisible')
  onOpen (dialogVisible: boolean) {
    if (dialogVisible) {
      this.loadData()
    }
  }

  get demoCategories () {
    return CategoryStore.demoCategories
  }

  mounted () {
    CategoryStore.loadData(CategoryType.DEMO)
    TagStore.loadData()
  }

  loadData () {
    RadioStore.loadMindUpMatchRule().then((mindUpMatchRule: any) => {
      this.formData = {
        categoryIds: mindUpMatchRule.categoryId ? CategoryStore.findParentAndSelf(mindUpMatchRule.categoryId!, CategoryType.DEMO).map(item => { return item.id! }) : [],
        tags: mindUpMatchRule.tagIds.map((id: number) => TagStore.getTagById(id)),
        options: mindUpMatchRule.options.map((o: any) => {
          return {
            ...o,
            defaultTags: [TagStore.getTagById(o.defaultTagId)],
            nonDefaultTags: [TagStore.getTagById(o.nonDefaultTagId)]
          }
        })
      }
    })
  }

  cancel () {
    this.dialogVisible = false
  }

  submit () {
    const invalidOption = this.formData.options.find(o => o.defaultTags.length === 0 || o.nonDefaultTags.length === 0)
    if (invalidOption) {
      ElMessage({ message: '请检查选项标签配置', type: 'error', showClose: true })
      return
    }

    const configData = {
      categoryId: this.formData.categoryIds.slice(-1)[0],
      tagIds: this.formData.tags.map(t => t.id),
      options: this.formData.options.map(o => { return { name: o.name, code: o.code, defaultTagId: o.defaultTags[0].id, nonDefaultTagId: o.nonDefaultTags[0].id } })
    }

    RadioStore.updateMindUpMatchRule(configData).then(() => {
      this.dialogVisible = false
    })
  }
}
