
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryType, Category, EditMode } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import { AuthStore } from '@/store/modules/auth'
import TagInput from '@/components/TagInput.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Slice } from '@/models/slice'
import PublishDialog from '@/components/PublishDialog.vue'

@Options({
  components: { TagInput, PublishDialog },
  emits: ['success', 'cancel']
})
export default class SliceForm extends Vue {
  editMode: EditMode = EditMode.ADD

  @Prop({ default: [] })
  categoryId!: number

  @Prop({
    default: {
      name: '',
      words: '',
      audio: '',
      tags: [],
      duration: 0,
      fileSize: 0,
      categoryIds: []
    }
  })
  slice!: Slice

  currentSlice = this.slice
  publishDialogVisible = false
  showUploading = false

  editing = false
  categoryPath: Category[] = []

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  authStore = AuthStore
  tokenHeader = { Authorization: this.authStore.token + '' }
  sliceCategoryStore = CategoryStore

  get isEditing () {
    return this.editMode === EditMode.ADD || (this.editMode === EditMode.MODIFY && this.editing)
  }

  handleUploadError () {
    this.showUploading = false
  }

  publish (data: any) {
    service.post(`/3l/slices/${this.slice.id}/versions`, data).then(() => {
      this.publishDialogVisible = false
      this.$router.push({ name: 'SliceVersionList', params: { id: this.slice.id! } })
    })
  }

  formData = {
    ...this.currentSlice,
    categoryIds: this.categoryIdPath
  }

  beforeUpload () {
    this.formData.audio = ''
    this.$nextTick(() => {
      this.showUploading = true
    })
  }

  @Watch('categoryId', { immediate: true })
  onCategoryIdChanged (categoryId?: number) {
    this.formData.categoryIds = this.categoryIdPath
  }

  get categoryIdPath () {
    if (this.categoryId) {
      return CategoryStore.findParentAndSelf(this.categoryId!, CategoryType.SLICE).map(item => { return item.id! })
    }

    return []
  }

  @Watch('slice', { immediate: true })
  onSliceChanged (slice: Slice) {
    this.editMode = slice.id ? EditMode.MODIFY : EditMode.ADD
    this.currentSlice = slice
    if (!this.currentSlice.categoryId) {
      this.categoryPath = [{
        parentId: 0,
        code: '未分类',
        type: CategoryType.SLICE,
        name: '未分类'
      }]
    } else {
      this.categoryPath = CategoryStore.findParentAndSelf(this.currentSlice.categoryId!, CategoryType.SLICE)
    }
  }

  startEdit () {
    this.editing = true
    this.formData = JSON.parse(JSON.stringify({ ...this.currentSlice, categoryIds: this.categoryPath.map(item => { return item.id! }) }))

    const form = this.$refs.form as any
    form.clearValidate()
  }

  handleAudioUploaded (res: any, file: any) {
    this.showUploading = false
    this.formData.audio = res.data.url
    this.formData.duration = res.data.duration
    this.formData.fileSize = res.data.fileSize
  }

  cancel () {
    this.editing = false
    this.formData = JSON.parse(JSON.stringify({ ...this.currentSlice, categoryIds: this.categoryPath.map(item => item.id!) }))
    this.$emit('cancel')
  }

  onSubmit () {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          categoryId: this.formData.categoryIds.slice(-1)[0]
        }
        service.post('/3l/slices', data).then((res: AxiosResponse) => {
          (this.$refs.form as any).resetFields()
          this.$emit('success', res.data)
        })
      } else {
        return false
      }
    })
  }

  onUpdate (needPublish: boolean) {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          categoryId: this.formData.categoryIds.slice(-1)[0]
        }
        service.post(`/3l/slices/${this.$route.params.id}`, data).then((res: AxiosResponse) => {
          this.currentSlice = res.data
          this.onSliceChanged(this.currentSlice)
          const form = this.$refs.form as any
          form.resetFields()
          this.editing = false
          this.publishDialogVisible = needPublish
        })
      } else {
        return false
      }
    })
  }
}
