
import { Options, Vue } from 'vue-class-component'
import { ElMessage } from 'element-plus'
import service from '@/utils/service'
import { AuthStore } from '@/store/modules/auth'
import { AxiosResponse } from 'axios'
import router from '@/router'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {}
})
export default class Login extends Vue {
  showRegister = false
  username = ''
  password = ''
  confirmPassword = ''

  register (): void {
    if (this.validateRegister()) {
      service.post('uaa/public/users', {
        appCode: '3L',
        username: this.username,
        password: this.password,
        phone: this.username
      }).then(() => {
        this.showRegister = false
      })
    }
  }

  beforeMount () {
    const code = this.$route.query.code
    if (code) {
      service.post('/uaa/public/wx/login', {
        appCode: '3L',
        code: code,
        appId: 'wx1533c7902186ee49'
      }).then((res: AxiosResponse) => {
        AuthStore.setToken(res.data.token)
        this.$router.push({ path: '/' })
      })
    }
  }

  @Watch('$route.name')
  onRouteChanged () {
    this.showRegister = this.$route.name === 'Register'
  }

  mounted () {
    this.showRegister = this.$route.name === 'Register'
    this.createWeCharQr('login_container')
    this.createWeCharQr('register_container')
  }

  private createWeCharQr (domId: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.WxLogin({
      self_redirect: false,
      id: domId,
      appid: 'wx1533c7902186ee49',
      scope: 'snsapi_login',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      redirect_uri: window.SSO_URL,
      state: 'login',
      style: '',
      href: ''
    })
  }

  login (): void {
    if (this.validateLogin()) {
      service.post('uaa/public/tokens', {
        appCode: '3L',
        username: this.username,
        password: this.password
      }).then((res: AxiosResponse) => {
        AuthStore.setToken(res.data.token)
        const nextUrl = (this.$route.query.redirect as string) || '/'
        this.$router.push({ path: nextUrl })
      })
    }
  }

  get user () {
    return AuthStore.user
  }

  logout () {
    this.showRegister = false
    AuthStore.removeToken()
    this.$router.push({ name: 'Login' })
  }

  validateLogin (): boolean {
    if (this.username.trim() === '') {
      ElMessage({ message: '手机号不可为空', type: 'error', showClose: true })
      return false
    }
    if (this.password.trim() === '') {
      ElMessage({ message: '密码不可为空', type: 'error', showClose: true })
      return false
    }
    return true
  }

  validateRegister (): boolean {
    if (this.username.trim() === '') {
      ElMessage({ message: '手机号不可为空', type: 'error', showClose: true })
      return false
    }
    if (this.password.trim().length < 6) {
      ElMessage({ message: '密码必须超过6位且不可有空格', type: 'error', showClose: true })
      return false
    }
    if (this.confirmPassword.trim() === '') {
      ElMessage({ message: '确认密码不可为空', type: 'error', showClose: true })
      return false
    }
    if (this.confirmPassword.trim() !== this.password.trim()) {
      ElMessage({ message: '密码与确认密码不一致', type: 'error', showClose: true })
      return false
    }
    if (!(/^1[3456789]\d{9}$/.test(this.username))) {
      ElMessage({ message: '手机号格式错误', type: 'error', showClose: true })
      return false
    }

    return true
  }
}
