
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {}
})
export default class CardSteps extends Vue {
  @Prop()
  keywordId!: number

  @Prop({ default: false })
  simple!: boolean

  @Prop({
    default: {
      preSelect: 'wait',
      crop: 'wait'
    }
  })
  status!: any

  @Prop({
    default: {
      preSelect: '初选',
      crop: '切图'
    }
  })
  titles!: any

  @Prop()
  width!: string

  preSelect () {
    if (this.status.preSelect === 'wait') {
      return
    }
    this.$router.push({ name: 'PreSelect', params: { id: this.keywordId } })
  }

  crop () {
    if (this.status.crop === 'wait') {
      return
    }
    this.$router.push({ name: 'Crop', params: { id: this.keywordId } })
  }
}
