
import { Options, Vue } from 'vue-class-component'
import { Model, Watch } from 'vue-property-decorator'
import { ElMessage } from 'element-plus'
import { RadioStore } from '@/store/modules/radio'
import { TimeBox } from '@/models/radio'

@Options({
  components: {},
  emits: ['success', 'cancel']
})
export default class TimeBoxesDialog extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  dialogVisible!: boolean

  currentIndex!: number
  currentTimeBox!: TimeBox
  calcTimePoint = RadioStore.calcTimePoint
  timeBoxes: TimeBox[] = []

  @Watch('dialogVisible')
  onOpen (dialogVisible: boolean) {
    if (dialogVisible) {
      this.loadData()
    }
  }

  loadData () {
    RadioStore.loadTimeBoxes().then((timeBoxes: TimeBox[]) => {
      this.timeBoxes = [...timeBoxes, { startTime: '24:00', name: '', endTime: '', visualMode: 'DARK', loopPlayback: false }]
      // this.timeBoxes = [...timeBoxes.map(t => { return { ...t, loopPlayback: !!t.loopPlayback } }), { startTime: '24:00', name: '', endTime: '', visualMode: 'DARK', loopPlayback: false }]
    })
  }

  setCurrentTimeBox (index: number, timeBox: TimeBox) {
    this.currentIndex = index
    this.currentTimeBox = timeBox
  }

  addTimeBoxAbove (time: string) {
    this.timeBoxes.splice(this.currentIndex, 0, {
      startTime: this.currentTimeBox.startTime,
      endTime: time,
      visualMode: this.currentTimeBox.visualMode,
      loopPlayback: false,
      name: ''
    })

    this.currentTimeBox.startTime = time
  }

  addTimeBoxBelow (time: string) {
    this.timeBoxes.splice(this.currentIndex + 1, 0, {
      startTime: time,
      endTime: this.currentTimeBox.endTime,
      visualMode: this.currentTimeBox.visualMode,
      loopPlayback: false,
      name: ''
    })

    this.currentTimeBox.endTime = time
  }

  removeTimeBox (index: number, timeBox: TimeBox) {
    this.timeBoxes.splice(index, 1)
    if (index < this.timeBoxes.length - 1) {
      this.timeBoxes[index].startTime = timeBox.startTime
    }
    if (index === this.timeBoxes.length - 1) {
      this.timeBoxes[index - 1].endTime = timeBox.endTime
    }
  }

  cancel () {
    this.dialogVisible = false
  }

  submit () {
    const timeBoxes = this.timeBoxes.slice(0, -1)
    const invalidTimeBox = timeBoxes.find(timeBox => {
      return !timeBox.name || timeBox.name.trim() === ''
    })

    if (invalidTimeBox) {
      ElMessage({ message: '时段名称不能为空!', type: 'warning', showClose: true })
      return
    }

    RadioStore.updateTimeBoxes(timeBoxes).then(() => {
      ElMessage({ message: '更新成功', type: 'success', showClose: true })
      this.dialogVisible = false
    })
  }
}
