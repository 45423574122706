
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { TagStore } from '@/store/modules/tags'
import { CommonCardTagCategory } from '@/models/card'
import { Tag } from '@/models/common'

@Options({
  components: { },
  emits: ['success', 'cancel']
})
export default class AlternativeCardList extends Vue {
  alternativeCards: any[] = []
  commonCardTagCategories: CommonCardTagCategory[] = []
  filterTags: Tag[] = []

  @Prop()
  cardToChange! : any

  @Prop()
  cardSetPartToChange!: any

  @Prop()
  excludeCardIds!: number[]

  changeCardConfirmed (card: any) {
    const cardIndexToRemove = this.cardSetPartToChange.previewResult.cards.findIndex((c : any) => c.id === this.cardToChange.id)
    this.cardSetPartToChange.previewResult.cards.splice(cardIndexToRemove, 1, card)
    this.alternativeCards = []
    this.$emit('success')
  }

  isSelectedTag (tagId: number) {
    return this.filterTags.findIndex(ct => ct.id === tagId) > -1
  }

  clickTag (tag: Tag) {
    if (this.isSelectedTag(tag.id!)) {
      this.filterTags = this.filterTags.filter(ct => ct.id !== tag.id)
    } else {
      this.filterTags.push(tag)
    }

    this.$forceUpdate()
    this.loadData()
  }

  mounted () {
    this.loadData()
    service.get('/3l/cards/common-tag-categories').then((res: AxiosResponse<CommonCardTagCategory[]>) => {
      this.commonCardTagCategories = res.data
    })
  }

  getTag (tagId: any) {
    return TagStore.getTagById(Number.parseInt(tagId))
  }

  @Watch('cardToChange')
  onCardToChangeChanged () {
    this.loadData()
  }

  @Watch('cardSetPartToChange')
  onCardSetPartToChangeChanged () {
    this.loadData()
  }

  loadData () {
    service.get('/3l/card-sets/alternative-cards', {
      params: {
        tagIds: [...new Set([...this.cardSetPartToChange.tagIds, ...this.filterTags.map(t => t.id)])].join(','),
        excludeCardIds: this.excludeCardIds
      }
    }).then((res: AxiosResponse<any[]>) => {
      this.alternativeCards = res.data
    })
  }
}
