import { Module, VuexModule, getModule, Action, Mutation } from 'vuex-module-decorators'
import store from '../index'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { CategoryType, Category } from '@/models/common'

const find = (id: number, categories: Category[]) : Category | undefined => {
  for (let index = 0; index < categories.length; index += 1) {
    const currentCategory = categories[index]
    if (currentCategory.id === id) {
      return currentCategory
    }
    if (currentCategory.children) {
      const result = find(id, currentCategory.children)
      if (result) {
        return result
      }
    }
  }
  return undefined
}

const getChildren = (category: Category, result: Category[]) : void => {
  if (!category?.children) {
    return
  }
  category.children.forEach(item => {
    result.push(item)
    getChildren(item, result)
  })
}

const getTypeUri = (type: CategoryType) => {
  return type.toLowerCase().replace('_', '-')
}

@Module({
  name: 'categoryStore',
  dynamic: true,
  namespaced: true,
  store
})
export default class CategoryStoreModule extends VuexModule {
  categoriesMap= new Map<string, Category[]>([
    [CategoryType.SLICE, []],
    [CategoryType.TAG, []],
    [CategoryType.DEMO, []],
    [CategoryType.TEMPLATE, []],
    [CategoryType.WORDS, []],
    [CategoryType.CARD_SET, []]
  ]);

  @Action
  loadData (type: CategoryType) : Promise<void> {
    return service.get(`/3l/${getTypeUri(type)}-categories`).then((res: AxiosResponse) => {
      this.categoriesLoaded({ type, data: res.data })
    })
  }

  @Mutation
  categoriesLoaded (categoryRes: any) {
    this.categoriesMap.set(categoryRes.type, categoryRes.data)
  }

  get sliceCategories () {
    return this.categoriesMap.get(CategoryType.SLICE)!
  }

  get wordsCategories () {
    return this.categoriesMap.get(CategoryType.WORDS)!
  }

  get tagCategories () {
    return this.categoriesMap.get(CategoryType.TAG)!
  }

  get cardSetCategories () {
    return this.categoriesMap.get(CategoryType.CARD_SET)!
  }

  get demoCategories () {
    return this.categoriesMap.get(CategoryType.DEMO)!
  }

  get templateCategories () {
    return this.categoriesMap.get(CategoryType.TEMPLATE)!
  }

  get findById () {
    return (id: number, type: CategoryType) : Category | undefined => {
      return find(id, this.categoriesMap.get(type)!)
    }
  }

  get getAllChildren () {
    return (category: Category) : Category[] => {
      const result: Category[] = []
      getChildren(category, result)
      return result
    }
  }

  get getAllChildrenById () {
    return (categoryId: number, type :CategoryType) : number[] => {
      const category = find(categoryId, this.categoriesMap.get(type)!)
      const result: Category[] = []
      getChildren(category!, result)
      return result.map(i => i.id!)
    }
  }

  get findParentAndSelf () {
    return (id: number, type: CategoryType) : Category[] => {
      const result: Category[] = []
      let category = find(id, this.categoriesMap.get(type)!)
      if (!category) {
        return result
      } else {
        result.push(category!)
        let parentId = category.parentId
        while (parentId !== 0) {
          category = find(parentId, this.categoriesMap.get(category!.type!)!)
          result.push(category!)
          parentId = category!.parentId
        }
        return result.reverse()
      }
    }
  }

  @Action
  delete (data: Category): Promise<void> {
    return service.delete(`/3l/${getTypeUri(data.type)}-categories/${data.id}`).then(() => {
      this.categoryDeleted(data)
    })
  }

  @Action
  create (data: any): Promise<Category> {
    return service.post(`/3l/${getTypeUri(data.type)}-categories`, data).then((res: AxiosResponse) => {
      this.categoryAdded(res.data)
      return res.data
    })
  }

  @Action
  update (data: any): Promise<Category> {
    return service.post(`/3l/${getTypeUri(data.type)}-categories/${data.id}`, data).then((res: AxiosResponse) => {
      this.categoryUpdated(res.data)
      return res.data
    })
  }

  @Mutation
  categoryAdded (category: Category) {
    if (category.parentId === 0) {
      this.categoriesMap.get(category.type)!.push(category)
      this.categoriesMap.get(category.type)!.sort((a:Category, b:Category) => { return a.code.localeCompare(b.code) })
    } else {
      const parent = find(category.parentId, this.categoriesMap.get(category.type)!)
      if (parent!.children) {
        parent!.children = [...parent!.children!, category].sort((a:Category, b:Category) => { return a.code.localeCompare(b.code) })
      } else {
        parent!.children = [category]
      }
    }
  }

  @Mutation
  categoryDeleted (data: Category) {
    const categories = this.categoriesMap.get(data.type)!
    const category = find(data.id!, categories)
    if (category!.parentId === 0) {
      const index = categories.indexOf(category!)
      categories.splice(index, 1)
    } else {
      const parent = CategoryStore.findById(category!.parentId!, category!.type!)
      const index = parent!.children!.indexOf(data)
      parent!.children!.splice(index, 1)
    }
  }

  @Mutation
  categoryUpdated (data: Category) {
    const category = find(data.id!, this.categoriesMap.get(data.type)!)
    category!.code = data.code
    category!.name = data.name
  }
}

export const CategoryStore = getModule(CategoryStoreModule)
