
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryType, Category, Tag } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import { CardSet, CardSetPart } from '@/models/card'
import { TagStore } from '@/store/modules/tags'
import { ElMessage } from 'element-plus'
import CardSetDetail from '@/components/CardSetDetail.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import TagInput from '@/components/TagInput.vue'
import CardSetPreview from '@/components/CardSetPreview.vue'

@Options({
  components: { CardSetDetail, TagInput, CardSetPreview },
  emits: ['success', 'cancel']
})
export default class CardSetForm extends Vue {
  @Prop({
    default: {
      name: '',
      tags: [],
      parts: [],
      cardCount: 64,
      description: ''
    }
  })
  cardSet!: CardSet

  previewDialogVisible = false

  parts: any[] = []

  errorTagIds: any[] = []

  currentCardSet = this.cardSet

  editing = false
  categoryPath: Category[] = []

  cardSetCategoryStore = CategoryStore

  formData = {
    ...this.currentCardSet,
    categoryIds: this.getCategoryIdPath()
  }

  openPreview () {
    this.previewDialogVisible = true
  }

  getTag (tagId: number) {
    return TagStore.getTagById(tagId)
  }

  getCategoryIdPath () {
    if (this.currentCardSet.categoryId) {
      return CategoryStore.findParentAndSelf(this.currentCardSet.categoryId!, CategoryType.CARD_SET).map(item => {
        return item.id!
      })
    }

    return []
  }

  @Watch('cardSet', { immediate: true })
  onCardSetChanged (cardSet: CardSet) {
    this.currentCardSet = cardSet
    if (!this.currentCardSet.categoryId) {
      this.categoryPath = [{
        parentId: 0,
        code: '未分类',
        type: CategoryType.CARD_SET,
        name: '未分类'
      }]
    } else {
      this.categoryPath = CategoryStore.findParentAndSelf(this.currentCardSet.categoryId!, CategoryType.CARD_SET)
    }

    this.formData = {
      ...this.currentCardSet,
      categoryIds: this.getCategoryIdPath()
    }

    this.parts = JSON.parse(JSON.stringify(this.cardSet.parts))
  }

  cancel () {
    this.editing = false
    this.$emit('cancel')
  }

  preview () {
    return service.post('/3l/card-sets/preview', this.parts.map(cardSetPart => {
      return {
        tagIds: cardSetPart.tagIds.join(','),
        cardCount: cardSetPart.cardCount
      }
    }))
  }

  isTotalCountError () {
    const totalCardCount = this.parts.map((p: any) => p.cardCount).reduce((prev: any, next: any) => {
      return prev + next
    })

    return totalCardCount !== this.formData.cardCount
  }

  onSubmit () {
    const form = (this.$refs.form as any)
    form.validate((valid: boolean) => {
      if (valid) {
        this.errorTagIds = []

        if (this.parts.filter(p => p.tagIds.length === 0).length > 1) {
          ElMessage({ message: '只能配一组随机卡牌', type: 'error', showClose: true })
          return
        }

        this.preview().then((previewResults: AxiosResponse<any[]>) => {
          let hasError = false
          previewResults.data.forEach((previewResult, index) => {
            if (previewResult.errorMessage) {
              ElMessage({ message: `标签组合:'${previewResult.tagIds.map((s: any) => this.getTag(s).name).join(',')}'存在问题，请预览检查`, type: 'error', showClose: true })
              this.errorTagIds.push(previewResult.tagIds)
              hasError = true
            }
          })

          if (this.isTotalCountError()) {
            ElMessage({ message: '卡牌数量分配有误', type: 'error', showClose: true })
            hasError = true
          }

          if (hasError) {
            return
          }

          const data = {
            ...this.formData,
            categoryId: this.formData.categoryIds.slice(-1)[0],
            parts: this.parts.map(p => {
              return {
                id: p.id,
                cardCount: p.cardCount,
                textVisible: !!p.textVisible,
                tagIds: p.tagIds
              }
            })
          }
          let url = '/3l/card-sets'
          if (this.currentCardSet.id) {
            url += '/' + this.currentCardSet.id
          }
          service.post(url, data).then((res: AxiosResponse) => {
            (this.$refs.form as any).resetFields()
            this.$emit('success', res.data)
          })
        })
      } else {
        return false
      }
    })
  }
}
