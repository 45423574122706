
import { Options, Vue } from 'vue-class-component'
import { Model, Prop, Watch } from 'vue-property-decorator'
import { AuthStore } from '@/store/modules/auth'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { RadioMenu } from '@/models/radio'

@Options({
  components: {},
  emits: ['success', 'cancel']
})
export default class RadioMenuDialog extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  dialogVisible!: boolean

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: AuthStore.token + '' }

  showLightIconUploading = false
  showDarkIconUploading = false

  @Prop()
  menu!: RadioMenu

  formData : RadioMenu = {
    name: '',
    sortNumber: 10,
    lightIcon: '',
    darkIcon: ''
  }

  @Watch('menu')
  onMenuChange (menu: RadioMenu) {
    this.formData = JSON.parse(JSON.stringify(this.menu))
  }

  handleLightIconUploadError () {
    this.showLightIconUploading = false
  }

  handleDarkIconUploadError () {
    this.showDarkIconUploading = false
  }

  beforeLightIconUpload () {
    this.formData.lightIcon = ''
    this.$nextTick(() => {
      this.showLightIconUploading = true
    })
  }

  beforeDarkIconUpload () {
    this.formData.darkIcon = ''
    this.$nextTick(() => {
      this.showLightIconUploading = true
    })
  }

  handleLightIconUploaded (res: any, file: any) {
    this.showLightIconUploading = false
    this.formData.lightIcon = res.data.url
  }

  handleDarkIconUploaded (res: any, file: any) {
    this.showLightIconUploading = false
    this.formData.darkIcon = res.data.url
  }

  submit () {
    (this.$refs.formDom as any).validate((valid: boolean) => {
      if (valid) {
        let uri = '/3l/radio/custom-menus'
        if (this.menu.id) {
          uri += `/${this.menu.id}`
        }
        service.post(uri, this.formData).then((res: AxiosResponse) => {
          this.$emit('success', res.data)
          this.dialogVisible = false
        })
      } else {
        return false
      }
    })
  }

  cancel () {
    this.dialogVisible = false
    this.$emit('cancel')
    this.formData = JSON.parse(JSON.stringify(this.menu))
  }
}
