
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryType, Category, EditMode } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Demo } from '@/models/demo'
import TagInput from '@/components/TagInput.vue'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: { TagInput },
  emits: ['success', 'cancel']
})
export default class DemoForm extends Vue {
  @Prop()
  categoryId!: number

  editMode = EditMode.ADD
  editing = false

  @Prop()
  demo!: Demo

  currentDemo = this.demo
  categoryPath: Category[] = []
  categoryStore = CategoryStore

  formData = {
    words: '',
    ...this.currentDemo,
    categoryIds: this.categoryIdPath
  }

  beforeUpload () {
    this.formData.demoUrl = ''
    this.$nextTick(() => {
      this.showUploading = true
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: AuthStore.token + '' }
  showUploading = false
  handleAudioUploaded (res: any, file: any) {
    this.showUploading = false
    this.formData.demoUrl = res.data.url
    this.formData.duration = res.data.duration
    this.formData.fileSize = res.data.fileSize
  }

  handleUploadError () {
    this.showUploading = false
  }

  @Watch('categoryId', { immediate: true })
  onCategoryIdsChanged () {
    this.formData.categoryIds = this.categoryIdPath
  }

  get categoryIdPath () {
    if (this.categoryId) {
      return CategoryStore.findParentAndSelf(this.categoryId!, CategoryType.DEMO).map(item => { return item.id! })
    }

    return []
  }

  get isEditing () {
    return this.editMode === EditMode.ADD || (this.editMode === EditMode.MODIFY && this.editing)
  }

  @Watch('demo', { immediate: true })
  onDemoChanged (demo: Demo) {
    this.editMode = demo.id ? EditMode.MODIFY : EditMode.ADD
    this.currentDemo = demo
    if (!this.currentDemo.categoryId) {
      this.categoryPath = [{
        parentId: 0,
        code: '未分类',
        type: CategoryType.DEMO,
        name: '未分类'
      }]
      this.formData = JSON.parse(JSON.stringify({ ...this.currentDemo, categoryIds: [], words: '' }))
    } else {
      this.categoryPath = CategoryStore.findParentAndSelf(this.currentDemo.categoryId!, CategoryType.DEMO)
      this.formData = JSON.parse(JSON.stringify({ ...this.currentDemo, categoryIds: this.categoryPath.map(item => item.id!), words: '' }))
    }

    if (!demo.templateId && demo.id) {
      service.get(`/3l/demos/${demo.id}/slices`).then((res: AxiosResponse) => {
        this.formData.words = res.data[0].words
      })
    }
  }

  cancel () {
    this.formData = JSON.parse(JSON.stringify({ ...this.currentDemo, categoryIds: this.categoryPath.map(item => item.id!), words: '' }))
    this.editing = false
    this.$emit('cancel')
  }

  submit () {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          categoryId: this.formData.categoryIds.slice(-1)[0],
          slices: this.formData.templateId ? this.demo!.slices : [{ words: this.formData.words }]
        }

        let uri = '/3l/demos'
        if (this.demo.id) {
          uri = `${uri}/${this.demo.id}`
        }
        service.post(uri, data).then((res: AxiosResponse) => {
          this.editing = false
          this.onDemoChanged(res.data)
          this.$emit('success', res.data)
        })
      } else {
        return false
      }
    })
  }
}
