
import { Options, Vue } from 'vue-class-component'
import DemoSlices from '@/components/DemoSlices.vue'
import PublishDialog from '@/components/PublishDialog.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Demo, DemoSlice } from '@/models/demo'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: { DemoSlices, PublishDialog }
})
export default class DemoCreator extends Vue {
  publishDialogVisible = false
  showUploading = false

  slices: DemoSlice[] = []
  demoUrl = ''
  demo?: Demo
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: AuthStore.token + '' }

  mounted () {
    Promise.all([service.get(`/3l/demos/${this.demoId}`), service.get(`/3l/demos/${this.demoId}/slices`)]).then((res: AxiosResponse[]) => {
      this.demo = res[0].data
      this.demoUrl = res[0].data.demoUrl
      this.slices = res[1].data

      if (!this.demo!.templateId) {
        this.slices[0].audio = this.demo!.demoUrl!
      }

      this.slices.push({
        sliceId: -1,
        slotId: -1,
        sortNumber: -1,
        sliceVersion: -1,
        duration: 0,
        fileSize: 0,
        audio: '',
        words: '',
        slotName: ''
      })
    })
  }

  beforeUpload () {
    this.showUploading = true
  }

  handleUploaded (res: any) {
    this.demoUrl = res.data.url
    service.post('')
  }

  handleUploadError () {
    this.showUploading = false
  }

  reMerge () {
    this.$router.push({ name: 'DemoCreator', params: { id: this.demo!.templateId! }, query: { demoId: this.demoId } })
  }

  goBack () {
    this.$router.push({ name: 'DemoList' })
  }

  publish (data: any) {
    service.post(`/3l/demos/${this.demoId!}/versions`, data).then(() => {
      this.publishDialogVisible = false
      this.$router.push({ name: 'DemoVersionList', params: this.$route.params })
    })
  }

  get demoId () : number {
    return Number.parseInt(this.$route.params.id as string)
  }
}
