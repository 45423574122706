
import { Options, Vue } from 'vue-class-component'
import WangEditor from 'wangeditor'
import { DomElementSelector } from 'wangeditor/dist/utils/dom-core'
import { Paragraph } from '@/models/book'
import { Model, Watch, Prop } from 'vue-property-decorator'
import { Words } from '@/models/words'

@Options({
  components: {}
})
export default class RichTextEditor extends Vue {
  instance: any

  @Model('modelValue', {
    type: String,
    default: ''
  })
  content!: string

  @Prop()
  initContent!: string

  @Watch('initContent')
  onInitContentChange (initContent: string) {
    this.instance.txt.html(initContent)
  }

  appendContent (textLibrary: Paragraph | Words) {
    this.instance.txt.append(`<p>${textLibrary.content}</p>`)
    if (typeof (textLibrary as Paragraph).bookName !== 'undefined') {
      const text = textLibrary as Paragraph
      this.instance.txt.append(`<p style="text-align: end">--  《${text.bookName}》${text.author ? ' ' + text.author : ''}</p>`)
    }
  }

  mounted () {
    this.instance = new WangEditor(this.$refs.editor as DomElementSelector)
    Object.assign(this.instance.config, {
      height: undefined,
      zIndex: 1,
      menus: [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'splitLine',
        'undo',
        'redo'
      ],
      onchange: (newHtml: string) => {
        this.content = newHtml
      }
    })
    this.instance.create()
    this.instance.txt.html(this.content)
  }

  beforeUnmount () {
    this.instance.destroy()
    this.instance = null
  }
}
