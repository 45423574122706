
import { Options, Vue } from 'vue-class-component'
import DemoList from '@/components/DemoList.vue'
import { Demo } from '@/models/demo'
import { Prop, Watch } from 'vue-property-decorator'
import { PlayItem } from '@/models/radio'
import service from '@/utils/service'
import dayjs from 'dayjs'
import { AxiosResponse } from 'axios'
import { RadioStore } from '@/store/modules/radio'
import { ElMessage } from 'element-plus'

@Options({
  components: { DemoList }
})
export default class RadioPlaylistTable extends Vue {
  dialogVisible = false
  currentPlayItem!: PlayItem
  playListSegments: any[] = []

  isEditing = false

  originalPlayList: PlayItem[] = []
  currentPlayList: PlayItem[] = []

  @Prop()
  day!: Date

  @Watch('day')
  onCurrentDayChange (date: Date, oldDate: Date) {
    if (oldDate.toISOString() !== date.toISOString()) {
      this.loadPlayList(date)
    }
  }

  mounted () {
    this.loadPlayList(new Date())
  }

  get isPastDay () : boolean {
    return dayjs(this.day).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')
  }

  toTimeStr (seconds: number) {
    const secondPart = seconds % 60
    const minutes = (seconds - secondPart) / 60
    const minutePart = minutes % 60
    const hours = (minutes - minutePart) / 60

    return `00${hours}`.slice(-2) + ':' + `00${minutePart}`.slice(-2) + ':' + `00${secondPart}`.slice(-2)
  }

  @Watch('timeBoxes')
  calcPlayListSegments () {
    const playListSegments = this.timeBoxes.map(timeBox => {
      return {
        ...timeBox,
        rowKey: timeBox.startTime,
        startTime: timeBox.startTime + ':00',
        endTime: timeBox.endTime + ':00',
        playlist: Array<PlayItem>()
      }
    })

    const playList = [...this.currentPlayList]

    playListSegments.forEach(playListSegment => {
      playListSegment.playlist = playList.filter(item => item.endTime <= playListSegment.endTime!)
      playList.splice(0, playListSegment.playlist.length)
      playListSegment.playlist.forEach(p => {
        p.parent = playListSegment
        p.rowKey = Math.random() + ''
      })
    })

    this.playListSegments = playListSegments
  }

  loadPlayList (date: Date) {
    service.get(`/3l/radio/calendar/${dayjs(date).format('YYYY-MM-DD')}`).then((res: AxiosResponse) => {
      this.originalPlayList = res.data
      this.currentPlayList = JSON.parse(JSON.stringify(this.originalPlayList))
      this.isEditing = (!this.isPastDay && (this.currentPlayList.length === 0))
      this.calcPlayListSegments()
    })
  }

  private calcStartAndEndTime (startSecond: number, list: PlayItem[]) {
    let totalDuration = startSecond
    for (let i = 0; i < list.length; i++) {
      const currentItem = list[i]
      currentItem.startTime = this.toTimeStr(totalDuration)
      totalDuration += currentItem.duration
      currentItem.endTime = this.toTimeStr(totalDuration)
    }
    this.$forceUpdate()
  }

  get timeBoxes () {
    return RadioStore.timeBoxes
  }

  cancel () {
    this.currentPlayList = this.originalPlayList
    this.isEditing = false
  }

  selectDemoFor (playItem: PlayItem) {
    this.currentPlayItem = playItem
    this.dialogVisible = true
  }

  demoSelected (demo: Demo) {
    this.currentPlayItem.demoId = demo.id!
    this.currentPlayItem.demoVersion = demo.latestVersion!
    this.currentPlayItem.audioUrl = demo.demoUrl
    this.currentPlayItem.duration = demo.duration
    if (!this.currentPlayItem.title) {
      this.currentPlayItem.title = demo.name
    }

    this.dialogVisible = false
    this.calcStartAndEndTime(RadioStore.getTotalMinutes(this.currentPlayItem.parent!.startTime) * 60, this.currentPlayItem.parent!.playlist)
  }

  removeAudio (item: PlayItem) {
    const playlistSegment = item.parent!
    const playlist = playlistSegment.playlist!
    item.parent = undefined
    playlist.splice(playlist.indexOf(item), 1)
    this.calcStartAndEndTime(RadioStore.getTotalMinutes(playlistSegment.startTime) * 60, playlist)
  }

  addAudio (row: any) {
    const isParent = !row.parent
    const parent = isParent ? row : row.parent

    const item = {
      title: '',
      demoId: 0,
      startTime: '',
      endTime: '',
      demoVersion: 0,
      duration: 0,
      audioUrl: '',
      parent: parent,
      rowKey: Math.random() + ''
    }

    if (isParent) {
      parent.playlist.splice(0, 0, item)
      this.$nextTick(() => {
        this.selectDemoFor(parent.playlist[0])
      })
    } else {
      const index = parent.playlist.indexOf(row)
      parent.playlist.splice(index + 1, 0, item)
      this.$nextTick(() => {
        this.selectDemoFor(parent.playlist[index + 1])
      })
    }
  }

  submit () {
    const playlist = this.playListSegments.flatMap(s => s.playlist).map(p => {
      return {
        ...p,
        parent: undefined,
        rowKey: undefined
      }
    })

    if (playlist.length === 0) {
      ElMessage({ message: '请添加音频', type: 'error', showClose: true })
      return
    }

    playlist.forEach(p => { p.hasError = (p.title.trim() === '' || p.demoId === 0) })

    if (playlist.find(p => p.hasError)) {
      ElMessage({ message: '请检查音频标题音频是否已全部填写！', type: 'error', showClose: true })
      return
    }

    const dayStr = dayjs(this.day).format('YYYY-MM-DD')
    service.post(`/3l/radio/calendar/${dayStr}`, { playlist })
      .then((res: AxiosResponse) => {
        this.originalPlayList = res.data
        this.currentPlayList = JSON.parse(JSON.stringify(this.originalPlayList))
        RadioStore.configuredDays.push(dayStr)
        ElMessage({ message: '保存成功', type: 'success', showClose: true })
        this.isEditing = false
      })
  }
}
