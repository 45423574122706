
import { Options, Vue } from 'vue-class-component'
import SearchInput from '@/components/SearchInput.vue'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { Book, Paragraph } from '@/models/book'
import ParagraphCard from '@/components/ParagraphCard.vue'
import RichTextEditor from '@/components/RichTextEditor.vue'
import WordsForm from '@/components/WordsForm.vue'
import { Words } from '@/models/words'
import { Watch } from 'vue-property-decorator'
import WordsCard from '@/components/WordsCard.vue'

@Options({
  components: {
    SearchInput,
    ParagraphCard,
    WordsCard,
    RichTextEditor,
    WordsForm
  }
})
export default class WordsEditor extends Vue {
  activeTab = 'paragraph'
  keyword = ''
  currentContent = ''
  initContent = ''
  allSelected = false
  paragraphList: Paragraph[] = []
  paragraphSearchResult = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<any>()
  }

  wordsList: Paragraph[] = []
  wordsSearchResult = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<any>()
  }

  words!: Words

  dialogVisible = false
  pageNum = 1

  loading = false
  books: Book[] = []
  selectedBooks: string[] = []

  mounted () {
    service.get('/library/books').then((res: AxiosResponse) => {
      this.books = res.data.content
    })
  }

  beforeMount () {
    if (this.$route.name === 'WordsEditor') {
      this.words = {
        name: '',
        categoryId: undefined,
        tags: [],
        content: ''
      }
      this.initContent = this.words.content!
      this.currentContent = this.words.content!
    } else {
      service.get(`/3l/words/${this.$route.params.id}`).then((res: AxiosResponse<Words>) => {
        this.words = res.data
        this.currentContent = this.words.content!
        this.initContent = this.words.content!
      })
    }
  }

  booksFilterVisible = false

  filterBooks () {
    this.booksFilterVisible = true
  }

  @Watch('allSelected')
  onAllSelectedChanged (allSelected: boolean) {
    if (allSelected) {
      this.selectedBooks = this.books.map(b => b.id)
    } else {
      this.selectedBooks = []
    }
  }

  get noMore () {
    if (this.activeTab === 'paragraph') {
      return this.paragraphSearchResult.totalPages === this.paragraphSearchResult.pageNumber
    } else {
      return this.wordsSearchResult.totalPages === this.wordsSearchResult.pageNumber
    }
  }

  get disabled () {
    return this.loading || this.noMore
  }

  @Watch('activeTab')
  onTabChange () {
    this.resetAndSearch()
  }

  resetAndSearch () {
    this.pageNum = 1
    this.paragraphList = []
    this.wordsList = []
    this.search()
  }

  saveWord () {
    this.words.content = this.currentContent
    if (this.$route.name === 'WordsEditor') {
      this.dialogVisible = true
    } else {
      service.post(`/3l/words/${this.$route.params.id}`, this.words).then((res: AxiosResponse<Words>) => {
        this.words = res.data
      })
    }
  }

  wordsUpdated (words: Words) {
    this.dialogVisible = false
    this.$router.push({ name: 'WordsDetailEditor', params: { id: words.id! } })
  }

  loadMore () {
    this.pageNum++
    this.search()
  }

  beforeFilterClose () {
    this.booksFilterVisible = false
    this.resetAndSearch()
  }

  handleSearch (keyword: string) {
    if (this.keyword.trim() === '') {
      ElMessage({ message: '请输入关键字后查询', type: 'warning', showClose: true })
      return
    }
    this.resetAndSearch()
  }

  search () {
    if (this.keyword.trim() === '') {
      return
    }
    this.loading = true
    const params = {
      keyword: this.keyword,
      pageNum: this.pageNum,
      bookIds: this.selectedBooks.length > 0 ? this.selectedBooks.join(',') : undefined,
      pageSize: 20
    }

    if (this.activeTab === 'paragraph') {
      service.get('/library/paragraphs', { params }).then((res: AxiosResponse) => {
        this.loading = false
        this.paragraphSearchResult = res.data
        this.paragraphList = [...this.paragraphList, ...this.paragraphSearchResult.content]
      })
    } else {
      service.get('/library/words', { params }).then((res: AxiosResponse) => {
        this.loading = false
        this.wordsSearchResult = res.data
        this.wordsSearchResult.content = this.wordsSearchResult.content.filter(w => w.id + '' !== this.$route.params.id + '')
        this.wordsList = [...this.wordsList, ...this.wordsSearchResult.content]
      })
    }
  }

  handleReference (textLib: Paragraph | Words) {
    (this.$refs.editor as any).appendContent(textLib)
  }
}
