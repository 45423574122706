
import { Options, Vue } from 'vue-class-component'
import SearchInput from '@/components/SearchInput.vue'
import { ElMessage } from 'element-plus'
import service from '@/utils/service'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { SearchInput }
})
export default class ImagePicker extends Vue {
  loading = false
  showSelectedOnly = false
  pageNum = 1
  keyword = ''
  pageSize = 30
  selectedImages: any[] = []
  images: any[] = []
  imageSearchResult = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<any>()
  }

  orientation = 'all'
  orientations=[{
    value: 'all',
    label: '全部方向'
  }, {
    value: 'landscape',
    label: '横向'
  }, {
    value: 'portrait',
    label: '竖向'
  }, {
    value: 'square',
    label: '正方形'
  }]

  size = 'all'
  sizes=[{
    value: 'all',
    label: '全部大小'
  }, {
    value: 'large',
    label: '大(24MP)'
  }, {
    value: 'medium',
    label: '中(12MP)'
  }, {
    value: 'small',
    label: '小(40MP)'
  }]

  color? : string
  predefineColors= [
    { name: 'red', value: 'f44336' },
    { name: 'pink', value: 'e91e63' },
    { name: 'violet', value: '673ab7' },
    { name: 'blue', value: '2196f3' },
    { name: 'turquoise', value: '009688' },
    { name: 'green', value: '4caf50' },
    { name: 'yellow', value: 'ffeb3b' },
    { name: 'brown', value: '795548' },
    { name: 'white', value: 'ffffff' },
    { name: 'black', value: '000000' }
  ]

  @Prop({ default: false })
  singleMode!: boolean

  submit () {
    this.$emit('selected', this.selectedImages)
    this.selectedImages = []
  }

  selectImage (image: any) {
    if (this.singleMode) {
      this.$emit('selected', image)
      return
    }

    const index = this.selectedImages.map(i => i.photoModalMediumId).indexOf(image.photoModalMediumId)
    if (index > -1) {
      this.selectedImages.splice(index, 1)
    } else {
      this.selectedImages.push(image)
    }
  }

  removeImage (image: any) {
    const index = this.selectedImages.map(i => i.photoModalMediumId).indexOf(image.photoModalMediumId)
    if (index > -1) {
      this.selectedImages.splice(index, 1)
    }
  }

  isSelected (image: any) {
    const index = this.selectedImages.map(i => i.photoModalMediumId).indexOf(image.photoModalMediumId)
    return index > -1
  }

  get noMore () {
    return this.imageSearchResult.totalPages === this.imageSearchResult.pageNumber
  }

  get disabled () {
    return this.loading || this.noMore
  }

  loadMore () {
    this.pageNum++
    this.search()
  }

  search () {
    if (this.keyword.trim() === '') {
      return
    }
    this.loading = true

    service.get('node-crawlers/pexels-images', {
      params: {
        keyword: this.keyword,
        pageNum: this.pageNum,
        orientation: this.orientation === 'all' ? undefined : this.orientation,
        size: this.size === 'all' ? undefined : this.size,
        color: this.getColorParam()
      }
    }).then((data: any) => {
      this.loading = false
      this.imageSearchResult = data
      for (let j = 0; j < this.imageSearchResult.content.length; j++) {
        this.imageSearchResult.content[j].number = (this.pageNum - 1) * this.pageSize + j
      }

      this.images = [...this.images, ...this.imageSearchResult.content]
    })
  }

  getColorParam () {
    if (!this.color) {
      return undefined
    }

    const predefineColor = this.predefineColors.find(c => `#${c.value.toUpperCase()}` === this.color)
    if (predefineColor) {
      return predefineColor.name
    }

    return this.color!.replace('#', '').toLowerCase()
  }

  handleSearchParam () {
    this.loading = true
    this.pageNum = 1
    this.images = []
    this.imageSearchResult = {
      count: 0,
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      totalPages: 0,
      content: Array<any>()
    }
    this.search()
  }

  handleSearch (keyword: string) {
    if (keyword.trim() === '') {
      ElMessage({ message: '请输入关键字后查询', type: 'warning', showClose: true })
    }
    this.handleSearchParam()
  }

  handleScroll () {
    if (this.loading) {
      return
    }
    const el = this.$refs.scrollContainer as any
    const touchedBottom = (el.scrollTop + el.clientHeight >= el.scrollHeight - 300)
    if (touchedBottom && !this.noMore) {
      this.loadMore()
    }
  }
}
