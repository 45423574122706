
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Book, Portion } from '@/models/book'

@Options({
  components: {}
})
export default class BookDetail extends Vue {
  book!: Book

  currentNode: Portion = {
    bigContentId: '',
    charSize: 0,
    children: [],
    contentUrl: '',
    grade: 0,
    id: '',
    leafNode: false,
    parentTitle: '',
    shareDescription: '',
    title: ''
  }

  treeData: Portion[] = []

  defaultProps = {
    children: 'children',
    label: 'title'
  }

  goBack () {
    this.$router.push({ name: 'BookList' })
  }

  beforeMount (): void {
    this.loadData()
  }

  handleNodeClick (data: Portion) {
    this.currentNode = data
  }

  loadData () {
    service.get(`/library/books/${this.$route.params.id}`).then((res: AxiosResponse) => {
      this.book = res.data
      if (this.book.portions.length === 1) {
        this.treeData = this.book.portions
      } else {
        this.treeData = this.book.portions.filter(p => p.grade === 0)
        this.treeData.forEach(p => { p.children = this.book.portions.filter(i => i.parentTitle === p.title) })
      }
      if (this.$route.query.articleId) {
        this.currentNode = this.book.portions.find(p => p.id === this.$route.query.articleId && p.bigContentId === this.$route.query.bigContentId)!
      } else {
        this.currentNode = this.treeData[0]
      }
    })
  }
}
