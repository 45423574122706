
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CategoryType, Category } from '@/models/common'
import { CategoryStore } from '@/store/modules/category'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Template } from '@/models/demo'

@Options({
  components: { },
  emits: ['success', 'cancel']
})
export default class TemplateForm extends Vue {
  @Prop({
    default: {
      name: '',
      categoryIds: []
    }
  })
  template!: Template

  currentTemplate = this.template
  categoryPath: Category[] = []

  get templateCategories () {
    return CategoryStore.templateCategories
  }

  formData = {
    ...this.currentTemplate,
    categoryIds: this.categoryIdPath
  }

  get categoryIdPath () {
    if (this.currentTemplate.categoryId) {
      return CategoryStore.findParentAndSelf(this.currentTemplate.categoryId, CategoryType.TEMPLATE).map(item => { return item.id! })
    }

    return []
  }

  @Watch('template', { immediate: true })
  onTemplateChanged (template: Template) {
    this.currentTemplate = template
    if (!this.currentTemplate.categoryId) {
      this.categoryPath = [{
        parentId: 0,
        code: '未分类',
        type: CategoryType.TEMPLATE,
        name: '未分类'
      }]
      this.formData = JSON.parse(JSON.stringify({ ...this.currentTemplate, categoryIds: [] }))
    } else {
      this.categoryPath = CategoryStore.findParentAndSelf(this.currentTemplate.categoryId!, CategoryType.TEMPLATE)
      this.formData = JSON.parse(JSON.stringify({ ...this.currentTemplate, categoryIds: this.categoryPath.map(item => item.id!) }))
    }
  }

  cancel () {
    this.formData = JSON.parse(JSON.stringify({ ...this.currentTemplate, categoryIds: this.categoryPath.map(item => item.id!) }))
    this.$emit('cancel')
  }

  onSubmit () {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        const data = {
          ...this.formData,
          categoryId: this.formData.categoryIds.slice(-1)[0]
        }

        let uri = '/3l/templates'
        if (this.template.id) {
          uri = `${uri}/${this.template.id}`
        }
        service.post(uri, data).then((res: AxiosResponse) => {
          (this.$refs.form as any).resetFields()
          this.$emit('success', res.data)
        })
      } else {
        return false
      }
    })
  }
}
