
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { Model, Prop, Watch } from 'vue-property-decorator'
import CardSetPreview from '@/components/CardSetPreview.vue'
import { CommonCardTagCategory } from '@/models/card'
import { Tag } from '@/models/common'
import { TagStore } from '@/store/modules/tags'

@Options({
  components: { CardSetPreview }
})
export default class CardSetDetail extends Vue {
  commonCardTagCategories: CommonCardTagCategory[] = []
  tagsVisible = false
  selectedTags: Tag[] = []
  previewDialogVisible = false
  previewData: any = []

  @Model('modelValue', {
    default: []
  })
  parts!: any[]

  @Prop({ default: [] })
  errorTagIds!: any[]

  @Prop()
  cardSetId?: number

  editRow: any

  hasError (tagIds: number[]) {
    return this.errorTagIds.findIndex(i => i.join(',') === tagIds.join(',')) > -1
  }

  @Prop({ default: 64 })
  cardCount!: number

  @Prop({ default: false })
  isEditing!: boolean

  preview (row: any) {
    this.previewDialogVisible = true
    this.previewData = [row]
  }

  removeTag (row: any, tagId: number) {
    row.tagIds = row.tagIds.filter((t: number) => t !== tagId)
  }

  edit (row: any) {
    this.editRow = row
    this.selectedTags = row.tagIds.map((t: number) => this.getTag(t))
    this.tagsVisible = true
  }

  getTag (tagId: string | number) {
    return TagStore.getTagById(Number.parseInt(tagId + ''))
  }

  deleteRow (row: any) {
    this.parts.splice(this.parts.indexOf(row), 1)
    this.splitDown()
  }

  addPart () {
    this.selectedTags = []
    this.tagsVisible = true
  }

  duplicate (row: any) {
    this.selectedTags = row.tagIds.map((t: number) => this.getTag(t))
    this.tagsVisible = true
  }

  isSelectedTag (tagId: number) {
    return this.selectedTags.findIndex(ct => ct.id === tagId) > -1
  }

  clickTag (tag: Tag) {
    if (this.isSelectedTag(tag.id!)) {
      this.selectedTags = this.selectedTags.filter(ct => ct.id !== tag.id)
    } else {
      this.selectedTags.push(tag)
    }

    this.$forceUpdate()
  }

  isTotalCountError () {
    const totalCardCount = this.parts.map((p: any) => p.cardCount).reduce((prev: any, next: any) => {
      return prev + next
    })

    return totalCardCount !== this.cardCount
  }

  tagConfirmed () {
    if (this.editRow) {
      const rowIndex = this.parts.indexOf(this.editRow)
      this.parts.splice(rowIndex, 1, {
        tagIds: this.selectedTags.map(t => t.id),
        cardCount: this.editRow.cardCount
      })
      this.editRow = undefined
    } else {
      this.parts.push({
        tagIds: this.selectedTags.map(t => t.id),
        cardCount: 1
      })
      this.splitDown()
    }
    this.tagsVisible = false
  }

  splitDown () {
    if (this.parts.length === 0) {
      return
    }

    const avgCount = Math.round(this.cardCount / (this.parts.length))
    this.parts.forEach((p: any) => { p.cardCount = avgCount })
    this.parts[this.parts.length - 1].cardCount = this.cardCount - avgCount * (this.parts.length - 1)
  }

  mounted () {
    service.get('/3l/cards/common-tag-categories').then((res: AxiosResponse<CommonCardTagCategory[]>) => {
      this.commonCardTagCategories = res.data.filter(c => c.tags.length > 0)
    })
  }
}
