
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ParagraphPanel from '@/components/ParagraphPanel.vue'
import { Words } from '@/models/words'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'

@Options({
  components: {
    ParagraphPanel
  },
  emits: ['reference']
})
export default class WordsCard extends Vue {
  @Prop() words!: Words
  surroundingVisible = false
  content = ''
  keywords!: Set<string>

  mounted () {
    this.content = this.words.content!
    const regExp = /<span class="highlight">([^<]*)<\/span>/g
    const keywordRegExp = /<span class="highlight">(.*)<\/span>/s
    this.keywords = new Set(this.content.match(regExp)!.map(s => s.match(keywordRegExp)![1]))
  }

  shrink () {
    this.surroundingVisible = false
    this.content = this.words.content!
  }

  reference (words: Words) {
    this.$emit('reference', { ...this.words, content: this.content })
  }

  showSurrounding () {
    this.surroundingVisible = true
    service.get(`/3l/words/${this.words.id}`).then((res: AxiosResponse<Words>) => {
      this.content = res.data.content!
      this.keywords.forEach(k => { this.content = this.content.replaceAll(k, '<span class="highlight">' + k + '</span>') })
    })
  }
}
