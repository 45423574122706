
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {},
  emits: ['success', 'cancel']
})
export default class PDFUploader extends Vue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/library/books/pdfs`
  tokenHeader = { Authorization: AuthStore.token + '' }
  showUploading = false
  url = ''

  handleUploaded (res: any, file: any) {
    this.showUploading = false
    this.$emit('success', res.data)
  }

  handleUploadError () {
    this.showUploading = false
  }

  beforeUpload () {
    this.url = ''
    this.$nextTick(() => {
      this.showUploading = true
    })
  }
}
