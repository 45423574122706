import { Tag } from '@/models/common'

export interface MindUpOption {
  code: string
  name: string
}

export interface MindUpMatchRule {
  tagIds?: number[]
  tags?: Tag[]
  categoryId?: number
  options?: any[]
}

export const MindUpOptions : MindUpOption[] = [
  { code: 'GESTURE', name: '姿势' },
  { code: 'GUIDE_MODE', name: '引导方式' },
  { code: 'DURATION', name: '时长' },
  { code: 'LEAVING_BLANK', name: '结尾方式' },
  { code: 'BGM', name: '背景音' },
  { code: 'TONE', name: '音色' }
]

export interface MindUpPractice {
  id? : number
  name: string
  sortNumber: number
  matchTagIds: number[]
  matchTags?: Tag[]
  options: MindUpOption[]
  lightBackground: string
  darkBackground: string
  audios: any[]
  version? : number
  latestVersion? : number
  latestOnlineVersion? : number
}
