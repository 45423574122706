
import { Options, Vue } from 'vue-class-component'
import MindUpMatchRuleDialog from '@/components/MindUpMatchRuleDialog.vue'
import { RadioStore } from '@/store/modules/radio'
import { MindUpPractice } from '@/models/mindup'
import MindUpPracticeForm from '@/components/MindUpPracticeForm.vue'
import { TagStore } from '@/store/modules/tags'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import MindUpMatchAudioTable from '@/components/MindUpMatchAudioTable.vue'
import { ElMessage } from 'element-plus'

@Options({
  components: {
    MindUpMatchRuleDialog,
    MindUpMatchAudioTable,
    MindUpPracticeForm
  }
})
export default class MindUpList extends Vue {
  dialogVisible = false
  matchDialogVisible = false
  practiceDialogVisible = false
  isShowOnlineVersion = false
  currentPractice: MindUpPractice = {
    name: '',
    sortNumber: 10,
    matchTagIds: [],
    options: [],
    lightBackground: '',
    darkBackground: '',
    audios: []
  }

  currentRowId = 0

  practices: MindUpPractice[] = []

  addPractice () {
    if (!RadioStore.mindUpMatchRule.options) {
      ElMessage({ message: '尚未配置匹配规则', type: 'error', showClose: true })
      return
    }

    this.currentPractice = {
      name: '',
      sortNumber: 10,
      matchTagIds: [],
      options: [],
      lightBackground: '',
      darkBackground: '',
      audios: []
    }
    this.practiceDialogVisible = true
  }

  get mindUpMatchRule () {
    return {
      ...RadioStore.mindUpMatchRule,
      tags: RadioStore.mindUpMatchRule.tagIds?.map(id => TagStore.getTagById(id)),
      options: RadioStore.mindUpMatchRule.options!.map(o => {
        return {
          ...o,
          defaultTag: TagStore.getTagById(o.defaultTagId),
          nonDefaultTag: TagStore.getTagById(o.nonDefaultTagId)
        }
      })
    }
  }

  switchOnlineVersion () {
    this.isShowOnlineVersion = !this.isShowOnlineVersion
    this.loadData()
  }

  expandRow (practice: MindUpPractice) {
    if (this.currentRowId === practice.id!) {
      this.currentRowId = 0
    } else {
      this.currentRowId = practice.id!
    }
  }

  online (practice: MindUpPractice) {
    service.post(`/3l/mind-up-practices/${practice.id}/online`).then((res: AxiosResponse) => {
      this.updatePractice(res.data)
    })
  }

  offline (practice: MindUpPractice) {
    service.post(`/3l/mind-up-practices/${practice.id}/offline`).then((res: AxiosResponse) => {
      if (this.isShowOnlineVersion) {
        this.practices = this.practices.filter(p => p.id !== practice.id)
      } else {
        this.updatePractice(res.data)
      }
    })
  }

  private updatePractice (practice: MindUpPractice) {
    const index = this.practices.findIndex(p => p.id === practice.id)
    if (index > -1) {
      this.practices.splice(index, 1, practice)
    } else {
      this.practices.push(practice)
    }
  }

  editPractice (practice: MindUpPractice) {
    this.currentPractice = practice
    this.practiceDialogVisible = true
  }

  matchAudios (practice: MindUpPractice) {
    this.currentPractice = practice
    this.matchDialogVisible = true
  }

  deletePractice (practice: MindUpPractice) {
    service.delete(`/3l/mind-up-practices/${practice.id}`).then(() => {
      this.practices = this.practices.filter(p => p.id !== practice.id)
    })
  }

  practiceUpdated (practice: MindUpPractice) {
    practice.matchTags = practice.matchTagIds.map(id => TagStore.getTagById(id)!)
    const index = this.practices.findIndex(p => p.id === practice.id)
    if (index > -1) {
      this.practices.splice(index, 1, practice)
    } else {
      this.practices.push(practice)
    }
    this.practiceDialogVisible = false
  }

  get badge () {
    return RadioStore.mindUpMatchRule.options ? '' : '!'
  }

  mounted () {
    Promise.all([RadioStore.loadMindUpMatchRule(), TagStore.loadData()]).then(() => this.loadData())
  }

  loadData () {
    service.get(`/3l/mind-up-practices?online=${this.isShowOnlineVersion}`).then((res: AxiosResponse) => {
      this.practices = res.data
      this.practices.forEach(p => { p.matchTags = p.matchTagIds.map(id => TagStore.getTagById(id)!) })
    })
  }
}
