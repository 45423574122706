
import { Options, Vue } from 'vue-class-component'
import { Model } from 'vue-property-decorator'
import TagInput from '@/components/TagInput.vue'
import service from '@/utils/service'

@Options({
  components: { TagInput },
  emits: ['success', 'cancel']
})
export default class CardKeywordCreateDialog extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  dialogVisible!: boolean

  formData = {
    keyword: '',
    manualUpload: false
  }

  create () {
    service.post('/3l/cards/keywords', this.formData).then((res: any) => {
      this.$emit('success', res.data)
    })
  }

  cancel () {
    this.dialogVisible = false
    this.$emit('cancel')
  }
}
