
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Book } from '@/models/book'
import CrawlBookDialog from '@/components/CrawlBookDialog.vue'
import PDFUploader from '@/components/PDFUploader.vue'
import { ElMessage } from 'element-plus'
import SearchInput from '@/components/SearchInput.vue'

@Options({
  components: {
    CrawlBookDialog, PDFUploader, SearchInput
  }
})
export default class BookList extends Vue {
  crawlDialogVisible = false
  currentBookId = ''
  currentBookSource = '163'
  searchName = ''

  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<Book>()
  }

  bookCrawled (book: Book) {
    ElMessage({ message: '添加成功', type: 'success', showClose: true })
    const existBookIndex = this.tableData.content.findIndex(item => book.id === item.id)
    if (existBookIndex > -1) {
      this.tableData.content.splice(existBookIndex, 1, book)
    } else {
      this.tableData.content.splice(0, 0, book)
      this.tableData.count++
      this.tableData.totalCount++
    }
  }

  reCrawl (book: Book) {
    this.currentBookId = book.id
    this.currentBookSource = this.getSource(book).code
    this.crawlDialogVisible = true
  }

  crawl () {
    this.currentBookId = ''
    this.currentBookSource = '163'
    this.crawlDialogVisible = true
  }

  isNumber (obj: string) {
    return obj === (+obj) + ''
  }

  getSource (book: Book) {
    if (this.isNumber(book.id)) {
      return { code: 'jd', name: '京东' }
    }

    if (book.portions.length === 1) {
      return { code: 'other', name: '其他' }
    }

    return { code: '163', name: '网易' }
  }

  beforeMount (): void {
    this.reloadPage(1)
  }

  handleSearch () {
    this.reloadPage(1)
  }

  reloadPage (pageNumber: number) {
    service.get('/library/books', {
      params: {
        pageNum: pageNumber,
        pageSize: 10,
        name: this.searchName
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  handleRowClick (book: Book) : void {
    this.$router.push({ name: 'BookDetail', params: { id: book.id! } })
  }

  deleteBook (book: Book) {
    service.delete(`/library/books/${book.id}`, {}).then(() => {
      this.tableData.totalCount--
      this.tableData.content = this.tableData.content.filter(item => { return item.id !== book.id })
    })
  }
}
