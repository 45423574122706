export enum EditMode {
  ADD = 'ADD',
  MODIFY = 'MODIFY'
}

export enum CategoryType {
  SLICE = 'SLICE',
  DEMO = 'DEMO',
  TEMPLATE = 'TEMPLATE',
  TAG = 'TAG',
  WORDS = 'WORDS',
  CARD_SET = 'CARD_SET'
}

export interface Category {
  id?: number
  parentId: number
  code: string
  type: CategoryType
  name: string
  children?: Category[]
}

export interface Tag {
  id?: number,
  category? : Category
  categoryId?: number
  name: string
}
