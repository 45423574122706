
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { Category, Tag } from '@/models/common'
import TagFilter from '@/components/TagFilter.vue'
import CategoryTree from '@/components/CategoryTree.vue'
import { CategoryStore } from '@/store/modules/category'
import CardSetForm from '@/components/CardSetForm.vue'
import { CardSet, CardSetPart } from '@/models/card'
import { TagStore } from '@/store/modules/tags'
import { MindUpPractice } from '@/models/mindup'
import CardSetDetail from '@/components/CardSetDetail.vue'
import CardSetPreview from '@/components/CardSetPreview.vue'
import CardSetAssemblyList from '@/components/CardSetAssemblyList.vue'

@Options({
  components: {
    CardSetDetail,
    CardSetPreview,
    CardSetForm,
    TagFilter,
    CategoryTree,
    CardSetAssemblyList
  }
})
export default class CardSetList extends Vue {
  filterTags: Tag[] = []
  currentSelectedCategories: Category[] = []
  currentCategoryId = 0
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<CardSet>()
  }

  previewDialogVisible = false
  currentRowId = 0
  cardSetDialogVisible = false
  currentSelectedCardSet! : CardSet
  previewCardSetId! : number
  cardSetParts: any[] = []

  preview (cardSet: CardSet) {
    this.cardSetParts = cardSet.parts
    this.previewCardSetId = cardSet.id!
    this.previewDialogVisible = true
  }

  getTag (tagId: number) {
    return TagStore.getTagById(tagId)
  }

  beforeMount (): void {
    this.reloadPage(1)
    TagStore.loadData()
  }

  expandRow (cardSet: CardSet) {
    if (this.currentRowId === cardSet.id!) {
      this.currentRowId = 0
    } else {
      this.currentRowId = cardSet.id!
    }
  }

  assemblyCreated (assembly: any) {
    this.currentRowId = 0
    this.previewDialogVisible = false
    this.$nextTick(() => {
      this.currentRowId = this.previewCardSetId
    })
  }

  addNew () {
    this.currentSelectedCardSet = {
      name: '',
      tags: [],
      parts: [],
      cardCount: 64,
      description: '',
      categoryId: this.currentSelectedCategories.length > 0 ? this.currentSelectedCategories[0].id : undefined
    }
    this.cardSetDialogVisible = true
  }

  cardSetCreated (data: any) {
    this.cardSetDialogVisible = false
    this.currentCategoryId = data.categoryId
    this.currentSelectedCategories = [data.category, ...CategoryStore.getAllChildren(data.category)]
    this.reloadPage(1)
  }

  reloadPage (pageNumber: number) {
    service.get('/3l/card-sets', {
      params: {
        categoryIds: this.categoryIds,
        tagIds: this.filterTags.length > 0 ? this.filterTags.map(item => item.id).join(',') : undefined,
        pageNum: pageNumber,
        pageSize: 10
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  get categoryIds () {
    return this.currentSelectedCategories.length > 0 ? this.currentSelectedCategories.map(i => { return i.id }).join(',') : undefined
  }

  filterByTags () {
    this.reloadPage(1)
  }

  filterCategory (currentSelectedCategories: Category[]) {
    this.currentSelectedCategories = currentSelectedCategories
    this.reloadPage(1)
  }

  edit (cardSet: CardSet) : void {
    this.currentSelectedCardSet = cardSet
    this.cardSetDialogVisible = true
  }

  deleteCardSet (cardSet: CardSet) {
    service.delete(`/3l/card-sets/${cardSet.id}`, {}).then(() => {
      this.tableData.totalCount--
      this.tableData.content = this.tableData.content.filter(item => { return item.id !== cardSet.id })
    })
  }
}
