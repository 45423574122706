
import { Options, Vue } from 'vue-class-component'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType, Tag } from '@/models/common'
import { TemplateSlot } from '@/models/demo'
import { AxiosResponse } from 'axios'
import service from '@/utils/service'
import SlotForm from '@/components/SlotForm.vue'

@Options({
  components: { SlotForm }
})
export default class TemplateSlots extends Vue {
  slots: TemplateSlot[] = []

  loadData () {
    service.get(`/3l/templates/${this.templateId}/slots`).then((res: AxiosResponse<Array<TemplateSlot>>) => {
      this.slots = res.data
      if (this.slots.length === 0) {
        this.slots = [{
          name: '',
          sortNumber: 0,
          tags: Array<Tag>()
        }]
      }
      this.slots.push({
        id: -1,
        name: '',
        sortNumber: 999999,
        tags: Array<Tag>()
      })
    })
  }

  getTitle (slot: TemplateSlot) {
    if (slot.id === -1) {
      return '结束'
    }

    return slot.name
  }

  addSlotAt (index: number) {
    this.slots.splice(index, 0, {
      name: '',
      sortNumber: index,
      tags: []
    })
  }

  removeSlotAt (index: number) {
    this.slots.splice(index, 1)
  }

  beforeMount () {
    CategoryStore.loadData(CategoryType.SLICE)
    this.loadData()
  }

  handleDeleted (slot: TemplateSlot) {
    if (slot.id) {
      this.slots = this.slots.filter(s => s.id !== slot.id)
    } else {
      this.slots = this.slots.filter(s => s !== slot)
    }
  }

  goBack () {
    this.$router.push({ name: 'TemplateList' })
  }

  slotUpdated (data: { index: number, slot: TemplateSlot }) {
    this.slots.splice(data.index, 1, data.slot)
  }

  get templateId () {
    return this.$route.params.id
  }
}
