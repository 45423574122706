
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { TagStore } from '@/store/modules/tags'

@Options({
  components: { }
})
export default class CardSetAssemblyView extends Vue {
  cardSetAssemblyParts: any[] = []

  @Prop()
  cardSetAssemblyId?: number

  @Watch('cardSetAssemblyId')
  onCardSetAssemblyIdChange () {
    this.loadAssemblyDetails()
  }

  mounted () {
    this.loadAssemblyDetails()
  }

  getTag (tagId: any) {
    return TagStore.getTagById(Number.parseInt(tagId))
  }

  loadAssemblyDetails () {
    service.get(`/3l/card-set-assemblies/${this.cardSetAssemblyId}`).then((res: AxiosResponse<any>) => {
      this.cardSetAssemblyParts = res.data.parts
    })
  }
}
