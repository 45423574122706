
import { Options, Vue } from 'vue-class-component'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { SliceVersion } from '@/models/slice'
import dayjs from 'dayjs'

@Options({
  components: {}
})
export default class SliceVersionList extends Vue {
  versions : SliceVersion[] = []

  beforeMount () {
    CategoryStore.loadData(CategoryType.SLICE).then(() => {
      service.get(`/3l/slices/${this.$route.params.id}/versions`).then((res: AxiosResponse) => {
        this.versions = res.data.reverse()
      })
    })
  }

  format (date: string) : string {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
  }

  goBack () {
    this.$router.push({ name: 'SliceList' })
  }
}
