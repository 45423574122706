
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Paragraph } from '@/models/book'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import ParagraphPanel from '@/components/ParagraphPanel.vue'

@Options({
  components: {
    ParagraphPanel
  },
  emits: ['reference']
})
export default class ParagraphCard extends Vue {
  @Prop() paragraph!: Paragraph
  preParagraphs: Paragraph[] = []
  surroundingParagraphs: Paragraph[] = [this.getParagraphWithoutHighlight(this.paragraph)]
  postParagraphs: Paragraph[] = []

  surroundingVisible = false
  surroundingCount = 0

  reference (paragraph: Paragraph) {
    this.$emit('reference', this.getParagraphWithoutHighlight(paragraph))
  }

  referenceAll () {
    if (this.surroundingVisible) {
      this.$emit('reference', { ...this.paragraph, content: [...this.surroundingParagraphs.map(p => p.content)].join('<br/>') })
    } else {
      this.$emit('reference', this.getParagraphWithoutHighlight(this.paragraph))
    }
  }

  getParagraphWithoutHighlight (paragraph: Paragraph) {
    return { ...this.paragraph, content: this.removeHighLight(paragraph.content) }
  }

  removeHighLight (content: string) : string {
    return content.replaceAll('<span class="highlight">', '').replaceAll('</span>', '')
  }

  showSurrounding () {
    if (!this.surroundingVisible && this.surroundingCount > 0) {
      this.surroundingVisible = true
      return
    }

    this.surroundingCount = this.surroundingCount + 5

    service.get('/library/paragraphs/surrounding', {
      params: {
        bookId: this.paragraph.bookId,
        bigContentId: this.paragraph.bigContentId,
        portionId: this.paragraph.portionId,
        sortNumber: this.paragraph.sortNumber,
        surroundingCount: this.surroundingCount
      }
    }).then((res: AxiosResponse<Paragraph[]>) => {
      this.surroundingVisible = true
      this.surroundingParagraphs = res.data
      this.preParagraphs = res.data.filter(p => { return p.sortNumber < this.paragraph.sortNumber })
      this.postParagraphs = res.data.filter(p => { return p.sortNumber > this.paragraph.sortNumber })
    })
  }
}
