
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import { Words } from '@/models/words'
import WordsForm from '@/components/WordsForm.vue'

@Options({
  components: { WordsForm }
})
export default class WordsBasic extends Vue {
  words: Words = {
    name: '',
    content: '',
    tags: []
  }

  beforeMount () {
    CategoryStore.loadData(CategoryType.WORDS).then(() => {
      service.get(`/3l/words/${this.$route.params.id}`).then((res: AxiosResponse) => {
        this.words = res.data
      })
    })
  }

  goBack () {
    this.$router.push({ name: 'WordsList' })
  }
}
