import axios from 'axios'
import { AuthStore } from '@/store/modules/auth'
import { ElLoading, ElMessage } from 'element-plus'

let needLoadingRequestCount = 0
let loadingInstance: any

function showFullScreenLoading (showLoading: boolean) {
  if (needLoadingRequestCount === 0 && showLoading) {
    loadingInstance = ElLoading.service()
  }
  needLoadingRequestCount++
}

function tryHideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) {
    return
  }
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    setTimeout(tryCloseLoading, 300)/* 300ms 间隔内的 loading 合并为一次 */
  }
}
function tryCloseLoading () {
  if (needLoadingRequestCount === 0) {
    loadingInstance && loadingInstance.close()
  }
}

const service = axios.create({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseURL: window.VUE_APP_BASE_API,
  timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (AuthStore.token) {
      config.headers.Authorization = AuthStore.token + ''
    }
    showFullScreenLoading(config.data?.showLoading !== false)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    tryHideFullScreenLoading()
    return response.data
  },
  error => {
    console.log('error  ====  ', error)
    tryHideFullScreenLoading()
    const status = error.response.status
    if (status === 401 || status === 403) {
      ElMessage({ message: '认证失败，请重新登录', type: 'error', showClose: true })
      AuthStore.removeToken()
      window.location.reload()
    } else {
      ElMessage.error(error.response.data.msg || '发生错误，请联系开发团队')
      return Promise.reject(error.response.data)
    }
  }
)

export default service
