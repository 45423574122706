
import { Options, Vue } from 'vue-class-component'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { SliceVersion } from '@/models/slice'
import dayjs from 'dayjs'
import DemoSlices from '@/components/DemoSlices.vue'

@Options({
  components: { DemoSlices }
})
export default class DemoVersionList extends Vue {
  versions : SliceVersion[] = []

  beforeMount () {
    CategoryStore.loadData(CategoryType.SLICE).then(() => {
      service.get(`/3l/demos/${this.$route.params.id}/versions`).then((res: AxiosResponse) => {
        this.versions = res.data.reverse()
        this.versions.forEach(version => version.slices.push({
          sliceId: -1,
          slotId: -1,
          sortNumber: -1,
          sliceVersion: -1,
          demoVersion: -1,
          duration: 0,
          fileSize: 0,
          audio: '',
          words: '',
          slotName: ''
        }))
      })
    })
  }

  format (date: string) : string {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
  }

  goBack () {
    this.$router.push({ name: 'DemoList' })
  }
}
