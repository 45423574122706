
import { Options, Vue } from 'vue-class-component'
import { Model, Prop, Watch } from 'vue-property-decorator'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'

@Options({
  components: {},
  emits: ['success', 'cancel']
})
export default class CrawlBookDialog extends Vue {
  @Model('modelValue', {
    type: Boolean,
    default: false
  })
  dialogVisible!: boolean

  @Prop({ default: '' })
  bookId! : string

  @Prop({ default: '' })
  bookSource! : string

  @Watch('bookId')
  onBookIdChange (bookId: string) {
    this.formData.bookId = bookId
  }

  @Watch('bookSource')
  onBookSourceChange (bookSource: string) {
    this.formData = {
      bookSource: this.bookSource,
      bookId: this.bookId,
      readerUCookie: '',
      u: '',
      articleUrl: '',
      ptKey: ''
    }
  }

  formData = {
    bookSource: this.bookSource,
    bookId: this.bookId,
    readerUCookie: '',
    u: '',
    articleUrl: '',
    ptKey: ''
  }

  bookSources = [{
    label: '网易',
    value: '163'
  }, {
    label: '京东',
    value: 'jd'
  }, {
    label: '微信公众号',
    value: 'wx-articles'
  }]

  confirm () {
    (this.$refs.formDom as any).validate((valid: boolean) => {
      if (valid) {
        let data: any
        let uri = `/library/books/${this.formData.bookId}`
        if (this.formData.bookSource === '163') {
          data = { readerUCookie: this.formData.readerUCookie }
        } else if (this.formData.bookSource === 'jd') {
          data = { uuid: this.formData.u, ptKey: this.formData.ptKey }
        } else if (this.formData.bookSource === 'wx-articles') {
          data = { url: this.formData.articleUrl }
          uri = '/library/books/wx-articles'
        }
        service.post(uri, data).then((res: AxiosResponse) => {
          this.$emit('success', res.data)
          this.formData.bookId = ''
          this.dialogVisible = false
        })
      } else {
        return false
      }
    })
  }

  cancel () {
    this.dialogVisible = false
    this.$emit('cancel')
  }
}
