
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import { Demo } from '@/models/demo'
import DemoForm from '@/components/DemoForm.vue'

@Options({
  components: { DemoForm }
})
export default class DemoBasic extends Vue {
  demo: Demo = {
    name: '',
    demoUrl: '',
    duration: 0,
    fileSize: 0,
    categoryId: 0,
    tags: [],
    slices: []
  }

  beforeMount () {
    CategoryStore.loadData(CategoryType.SLICE).then(() => {
      service.get(`/3l/demos/${this.$route.params.id}`).then((res: AxiosResponse) => {
        this.demo = res.data
      })
    })
  }

  goBack () {
    this.$router.push({ name: 'DemoList' })
  }
}
