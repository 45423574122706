
import { Options, Vue } from 'vue-class-component'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import { Watch } from 'vue-property-decorator'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import CardSteps from '@/components/CardSteps.vue'
import { Card, CardImage, CardKeyword, PageStatus } from '@/models/card'
import CropedCardList from '@/components/CropedCardList.vue'
import { CategoryStore } from '@/store/modules/category'
import { CategoryType } from '@/models/common'
import { ElMessage } from 'element-plus'
import CardTagEditor from '@/components/CardTagEditor.vue'
import { ImagesStore } from '@/store/modules/images'

@Options({
  components: {
    CardTagEditor,
    CropedCardList,
    CardSteps
  },
  emits: ['select-image', 'last-image', 'next-image']
})
export default class Crop extends Vue {
  myCropper!: Cropper
  imagePickerVisible = false
  categoryStore = CategoryStore
  tagsPanelVisible = false
  matrix = Number.parseInt(localStorage.getItem('cropMatrix') || '1')
  pageStatus: Array<PageStatus> = []
  currPageNum = 1
  images: CardImage[] = []
  originalImageTags: string[] = []
  image?: CardImage
  cardKeyword?: CardKeyword
  lastImageIndex = 0
  selectedCard?: Card
  nextImageIndex = 0
  cards: Array<Card> = []
  private readonly fixWidth = 108
  private readonly fixHeight = 144

  get preSelectedImages () {
    const images = this.images.filter(i => i.selected)
    const jsonImages = JSON.parse(JSON.stringify(images))
    return jsonImages
  }

  get id () {
    return this.$route.params.id
  }

  get currentPage (): PageStatus {
    return this.pageStatus.find(p => p.pageNum === this.currPageNum)!
  }

  get storeImages () {
    return JSON.parse(JSON.stringify(ImagesStore.images))
  }

  get passedInImage (): any {
    const image = this.$route.params.image
    if (image) {
      return JSON.parse(image + '')
    } else {
      return {}
    }
  }

  onCardClick (card: Card) {
    this.selectedCard = card
    this.tagsPanelVisible = true
  }

  @Watch('tagsPanelVisible')
  onTagsPanelVisibleChanged (value: boolean) {
    if (!value) {
      this.selectedCard = undefined
    }
  }

  isVerticalImage (image: CardImage) {
    return image?.pexelsImageInfo?.photoModalHeight > image?.pexelsImageInfo?.photoModalWidth
  }

  mounted () {
    CategoryStore.loadData(CategoryType.TAG)
    if (this.storeImages.length > 0) {
      this.pageStatus = []
      this.currPageNum = 0
    } else {
      service.get(`/3l/cards/keywords/${this.id}/page-status`).then((res: AxiosResponse) => {
        this.pageStatus = res.data
        this.currPageNum = this.pageStatus[0].pageNum
      })
    }
    service.get(`/3l/cards/keywords/${this.id}`).then((res: AxiosResponse) => {
      this.cardKeyword = res.data
    })

    if (this.storeImages.length > 0) {
      this.lastImageIndex = -1
      this.nextImageIndex = 1
      this.cards = []
      this.originalImageTags = []
      this.imagePickerVisible = false
      this.images = this.storeImages.map((img: any) => {
        return {
          selected: true,
          croped: false,
          pexelsImageId: -1,
          pexelsImageInfo: {
            photoModalImageGridItemSrc: img.url,
            photoModalImageDownloadLink: img.url,
            photoModalDownloadValueOriginal: `${img.width}x${img.height}`
          }
        }
      })
      // 复制完就销毁咯
      ImagesStore.setImages([])

      this.image = JSON.parse(JSON.stringify(this.images[0]))
      service.post('/files/preload', {
        file: 'https://static.guanxintec.com/content/b57decbd-1571-4163-9cc7-3f1834aa66be.png',
        showLoading: false
      })
      service.get('/3l/cards', { params: { pexelsImageId: this.image?.pexelsImageId } }).then((res: AxiosResponse) => {
        this.cards = res.data.content
      })
      this.$nextTick(() => {
        this.init()
      })
    }

    // if (!this.passedInImage.url) {
    //   service.get(`/3l/cards/keywords/${this.id}/page-status`).then((res: AxiosResponse) => {
    //     this.pageStatus = res.data
    //     this.currPageNum = this.pageStatus[0].pageNum
    //   })
    // } else {
    //   this.pageStatus = []
    //   this.currPageNum = 0
    // }
    //
    // if (this.passedInImage.url) {
    //   this.lastImageIndex = -1
    //   this.nextImageIndex = 0
    //   this.image = {
    //     selected: true,
    //     croped: false,
    //     pexelsImageId: -1,
    //     pexelsImageInfo: {
    //       photoModalImageGridItemSrc: this.passedInImage.url,
    //       photoModalImageDownloadLink: this.passedInImage.url,
    //       photoModalDownloadValueOriginal: `${this.passedInImage.width}x${this.passedInImage.height}`
    //     }
    //   }
    //   this.cards = []
    //   this.imagePickerVisible = false
    //
    //   service.post('/files/preload', {
    //     file: this.passedInImage.url,
    //     showLoading: false
    //   })
    //   this.originalImageTags = []
    //   this.$nextTick(() => {
    //     this.init()
    //   })
    // }
  }

  cropImage (index: number) {
    const img = (this.$refs[`img-${index}`] as any)
    const screenShotInfo = JSON.parse(img.getAttribute('crop'))
    service.post('/3l/cards', {
      ...screenShotInfo,
      pexelsImageId: this.image?.pexelsImageId,
      keywordId: this.id
    }).then((res: AxiosResponse) => {
      this.selectedCard = res.data
      this.cards.push(res.data)
      this.tagsPanelVisible = true

      if (this.cards.length === 1) {
        this.currentPage.cropedCount = (this.currentPage.cropedCount || 0) + 1
      }
    })
  }

  cardRemoved (id: number) {
    this.cards = this.cards.filter(s => s.id !== id)

    if (this.cards.length === 0) {
      this.currentPage.cropedCount--
    }
  }

  selectImage (index: number) {
    this.lastImageIndex = index - 1
    this.nextImageIndex = index + 1
    this.image = this.preSelectedImages[index]
    this.cards = []
    this.imagePickerVisible = false

    service.post('/files/preload', {
      file: this.image?.pexelsImageInfo.photoModalImageDownloadLink.split('?')[0],
      showLoading: false
    })

    service.get(`/node-crawlers/pexels-images/${this.image?.pexelsImageId}/tags`).then((res: any) => {
      this.originalImageTags = res
    })

    service.get('/3l/cards', { params: { pexelsImageId: this.image?.pexelsImageId } }).then((res: AxiosResponse) => {
      this.cards = res.data.content
    })
    this.$nextTick(() => {
      this.init()
    })
  }

  getPage (pageNum: number) {
    return this.pageStatus.find(p => p.pageNum === pageNum)!
  }

  changePage (pageNum: number) {
    this.imagePickerVisible = true
    this.currPageNum = pageNum
  }

  @Watch('matrix')
  onMatrixChanged (value: number) {
    localStorage.setItem('cropMatrix', value + '')
    this.$nextTick(() => {
      this.cropMoved()
    })
  }

  @Watch('imagePickerVisible')
  getImages (imagePickerVisible: boolean) {
    if (imagePickerVisible) {
      service.get(`/3l/cards/keywords/${this.id}/images`, {
        params: {
          pageNum: this.currPageNum,
          selected: true
        }
      }).then((res: AxiosResponse) => {
        this.images = res.data
        this.currentPage.selectedCount = this.images.filter(i => i.selected).length
      })
    }
  }

  cropMoved () {
    const imageData = this.myCropper.getImageData()
    const canvasData = this.myCropper.getCanvasData()
    const cropBoxData = this.myCropper.getCropBoxData()
    const {
      width: cropBoxWidth,
      height: cropBoxHeight
    } = cropBoxData
    const {
      width,
      height
    } = imageData
    const left = cropBoxData.left - canvasData.left - imageData.left
    const top = cropBoxData.top - canvasData.top - imageData.top

    const el = (this.$refs.preview as any)
    if (!el) {
      return
    }

    const data = {
      width: el.offsetWidth / this.matrix,
      height: el.offsetHeight / this.matrix
    }
    const originalWidth = data.width
    const originalHeight = data.height
    let cropWidth = originalWidth
    let cropHeight = originalHeight
    let ratio = 1

    if (cropBoxWidth) {
      ratio = originalWidth / cropBoxWidth * 1.5
      cropHeight = cropBoxHeight * ratio
    }

    if (cropBoxHeight && cropHeight > originalHeight) {
      ratio = originalHeight / cropBoxHeight * 1.5
      cropWidth = cropBoxWidth * ratio
      cropHeight = originalHeight * 1.5
    }

    for (let i = 0; i < this.matrix * this.matrix; i++) {
      const otherEl = (this.$refs['preview-' + i] as any)
      otherEl.style.cssText = `width: ${cropWidth}px; height: ${cropHeight}px;`
    }

    const position = {
      width: width * ratio,
      height: height * ratio,
      left: left * ratio,
      top: top * ratio
    }

    for (let row = 0; row < this.matrix; row++) {
      for (let col = 0; col < this.matrix; col++) {
        const imageStyle = {
          width: position.width * this.matrix,
          height: position.height * this.matrix,
          translateX: -(position.left + cropWidth / this.matrix * col) * this.matrix,
          translateY: -(position.top + cropHeight / this.matrix * row) * this.matrix
        }

        const img = (this.$refs['img-' + (row * this.matrix + col)] as any)
        img.style.cssText = `display: block; width: ${imageStyle.width}px; height: ${imageStyle.height}px; transform: translateX(${imageStyle.translateX}px) translateY(${imageStyle.translateY}px);`
        const cropData = {
          cropWidth: cropWidth,
          cropHeight: cropHeight,
          imageWidth: imageStyle.width,
          imageHeight: imageStyle.height,
          offsetX: -imageStyle.translateX,
          offsetY: -imageStyle.translateY,
          src: this.image?.pexelsImageInfo.photoModalImageDownloadLink.split('?')[0],
          originalWidth: this.image?.pexelsImageInfo.photoModalDownloadValueOriginal.split('x')[0],
          originalHeight: this.image?.pexelsImageInfo.photoModalDownloadValueOriginal.split('x')[1]
        }
        img.setAttribute('crop', JSON.stringify({
          cropData,
          width: cropData.cropWidth * cropData.originalWidth / cropData.imageWidth,
          height: cropData.cropHeight * cropData.originalHeight / cropData.imageHeight,
          offsetX: cropData.offsetX * cropData.originalWidth / cropData.imageWidth,
          offsetY: cropData.offsetY * cropData.originalHeight / cropData.imageHeight,
          originalWidth: cropData.originalWidth,
          originalHeight: cropData.originalHeight,
          originalImageUrl: cropData.src
        }))
      }
    }
  }

  init () {
    const el = (this.$refs.preview as any)
    el.style.cssText = `width: ${this.fixWidth * 4}px; height: ${this.fixHeight * 4}px;`

    if (this.myCropper) {
      this.myCropper.destroy()
    }
    this.myCropper = new Cropper((this.$refs.image as any), {
      preview: '.preview',
      viewMode: 1,
      aspectRatio: 3 / 4,
      dragMode: 'move',
      crop: this.cropMoved
    })
  }
}
